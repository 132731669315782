import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import {
  DATE_TIME_VALIDATION,
  DEFAULT_DATE_FORMAT,
} from '../../common/constants';
import { sendAnalyticsData } from '../../common/utils';
import DatePickerComponent from '../DatePickerComponent';
import RangePickerComponent from '../RangePickerComponent';

const { RangePicker } = TimePicker;
const RenderCustomDateTime = ({
  widgetConfig = null,
  pageId = null,
  form = null,
  editData = null,
}) => {
  let defaultValue;
  const rangePickerRef = useRef();
  const datePickerRef = useRef();
  const {
    dateTimeType = [],
    dateDefaultValue = 'TODAY',
    label,
    rules: { hideLabel = false, readOnly = false, required = false } = {},
    tooltip: toolTipCheck = false,
    tooltipValue: toolTipValue,
    dateConfig: { exactDate } = {},
    format = DEFAULT_DATE_FORMAT,
    allowDateTime = [],
    rangeDefaultValue = null,
    dateTimeBooleanValue = null,
    timeDefaultValue = null,
  } = widgetConfig?.config || {};

  const dateTimeValue = Form?.useWatch(['customFields', pageId], form);

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({ [label]: moment(dateTimeValue)?.format(format) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTimeValue]);

  const disabledTime = () => {
    const currentHour = moment()?.hour();
    const currentMinute = moment()?.minute();
    return {
      disabledHours: () => {
        const hours = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= 24; i++) {
          if (
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE) &&
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST)
          ) {
            return [];
          }
          if (
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE) &&
            i < currentHour
          ) {
            hours?.push(i);
          } else if (
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST) &&
            i > currentHour
          ) {
            hours?.push(i);
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour) => {
        const minutes = [];
        if (selectedHour === currentHour) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i <= 59; i++) {
            if (
              allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE) &&
              allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST)
            ) {
              return [];
            }
            if (
              allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE) &&
              i < currentMinute
            ) {
              minutes?.push(i);
            } else if (
              allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST) &&
              i > currentMinute
            ) {
              minutes?.push(i);
            }
          }
        }
        return minutes;
      },
    };
  };

  const renderPicker = () => {
    if (dateTimeType?.includes('DATE') && dateTimeType?.includes('TIME')) {
      if (exactDate) {
        if (dateTimeBooleanValue) {
          defaultValue = moment();
        } else {
          defaultValue = null;
        }
        return (
          <DatePickerComponent
            showTime
            dateTimeFormat={format}
            defaultValue={defaultValue}
            disabled={readOnly}
            use12Hours
            disabledDate={!(allowDateTime?.length > 1)}
            disableFutureDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION?.ALLOW_PAST,
            )}
            disablePastDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION?.ALLOW_FUTURE,
            )}
            disabledTime={disabledTime}
            isCurrentDateAllowed
          />
        );
      }
      if (rangeDefaultValue) {
        if (allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE)) {
          defaultValue = [
            moment()?.startOf('day'),
            moment()?.add(rangeDefaultValue, 'days')?.endOf('day'),
          ];
        } else {
          defaultValue = [
            moment()?.startOf('day'),
            moment()?.add(-rangeDefaultValue, 'days')?.endOf('day'),
          ];
        }
      }
      return (
        <RangePickerComponent
          showTime
          className="common-range-picker fill-width"
          popupClassName="common-range-picker"
          disabled={readOnly}
          defaultValue={defaultValue}
          dateTimeFormat={format}
          disabledDate={!(allowDateTime?.length > 1)}
          disableFutureDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE)
          }
          disablePastDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST)
          }
          isCurrentDateAllowed
        />
      );
    }
    if (dateTimeType?.includes('DATE')) {
      if (exactDate) {
        if (dateDefaultValue === 'TODAY') {
          defaultValue = moment();
        } else if (dateDefaultValue === 'TOMORROW') {
          defaultValue = moment()?.add(1, 'days');
        } else {
          defaultValue = null;
        }
        return (
          <DatePickerComponent
            datePickerRef={datePickerRef}
            dateFormat={format}
            disabledDate={!(allowDateTime?.length > 1)}
            disableFutureDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION?.ALLOW_PAST,
            )}
            disablePastDate={allowDateTime?.includes(
              DATE_TIME_VALIDATION?.ALLOW_FUTURE,
            )}
            disabled={readOnly}
            defaultValue={defaultValue}
            onChange={(value) => {
              datePickerRef.current.value = value;
            }}
            isCurrentDateAllowed
          />
        );
      }
      if (rangeDefaultValue) {
        if (allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE)) {
          defaultValue = [moment(), moment()?.add(rangeDefaultValue, 'days')];
        } else {
          defaultValue = [moment(), moment()?.add(-rangeDefaultValue, 'days')];
        }
      }

      return (
        <RangePickerComponent
          refs={rangePickerRef}
          dateFormat={format}
          className="common-range-picker fill-width"
          popupClassName="common-range-picker"
          disabledDate={!(allowDateTime?.length > 1)}
          disableFutureDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE)
          }
          disablePastDate={
            allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_FUTURE) &&
            !allowDateTime?.includes(DATE_TIME_VALIDATION?.ALLOW_PAST)
          }
          disabled={readOnly}
          defaultValue={defaultValue}
          onChange={(values) => {
            const rangeObj = [moment(values?.[0]), moment(values?.[1])];
            rangePickerRef.current.value = rangeObj;
          }}
          isCurrentDateAllowed
        />
      );
    }
    if (dateTimeType?.includes('TIME')) {
      if (exactDate) {
        if (dateTimeBooleanValue) {
          defaultValue = moment();
        } else {
          defaultValue = null;
        }

        return (
          <TimePicker
            className="common-time-picker fill-width custom-picker"
            format={format}
            use12Hours
            disabledTime={disabledTime}
            disabled={readOnly}
            defaultValue={defaultValue}
          />
        );
      }
      if (timeDefaultValue) {
        defaultValue = [
          moment(timeDefaultValue?.[0]),
          moment(timeDefaultValue?.[1]),
        ];
      } else {
        defaultValue = null;
      }
      return (
        <RangePicker
          className="common-range-picker fill-width"
          use12Hours
          format={format}
          defaultValue={defaultValue}
          disabled={readOnly}
          disabledTime={disabledTime}
        />
      );
    }
  };

  useEffect(() => {
    if (editData?.[pageId]?.length === 2) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: [
            moment(editData?.[pageId]?.[0]),
            moment(editData?.[pageId]?.[1]),
          ],
        },
      });
    } else if (editData?.[pageId]) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: editData?.[pageId]?.[0]
            ? moment(editData?.[pageId]?.[0])
            : null,
        },
      });
    } else if (
      Object.keys(editData)?.includes(pageId) &&
      !editData?.[pageId] &&
      !(required && readOnly)
    ) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: null,
        },
      });
    } else {
      form?.setFieldsValue({
        customFields: {
          [pageId]: defaultValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, editData]);

  return (
    <div>
      <Form.Item
        name={['customFields', pageId]}
        label={hideLabel ? ' ' : label}
        tooltip={
          toolTipCheck && {
            title: toolTipValue,
            icon: <InfoCircleOutlined />,
          }
        }
        rules={[
          { required, message: 'This field is required. Please fill it in.' },
        ]}
        className={`${toolTipCheck && 'label-with-tooltip'}`}
      >
        {renderPicker()}
      </Form.Item>
    </div>
  );
};

export default RenderCustomDateTime;
