import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { sendAnalyticsData } from '../../common/utils';
import BooleanChecked from '../BooleanChecked';
import BooleanUnchecked from '../BooleanUnchecked';

const RenderCustomBoolean = ({
  widgetConfig = null,
  setSavedData,
  editData = null,
  savedData = null,
  pageId = null,
  primaryColor = null,
}) => {
  const booleanDefaultValue = widgetConfig?.config?.booleanDefaultValue;
  const falseValue = widgetConfig?.config?.falseValue;
  const trueValue = widgetConfig?.config?.trueValue;
  const label = widgetConfig?.config?.label;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const readOnly = widgetConfig?.config?.rules?.readOnly;
  const required = widgetConfig?.config?.rules?.required;
  const toolTipChecked = widgetConfig?.config?.tooltip;
  const toolTipValue = widgetConfig?.config?.tooltipValue;
  const [selectedCard, setSelectedCard] = useState(booleanDefaultValue);

  useEffect(() => {
    if (editData) {
      if (!isEmpty(editData?.[pageId])) {
        setSelectedCard(editData?.[pageId]?.[0]);
      } else {
        setSelectedCard(`${booleanDefaultValue}`);
      }
    } else {
      setSelectedCard(`${booleanDefaultValue}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, booleanDefaultValue]);

  useEffect(() => {
    setSavedData({
      customFields: {
        ...savedData?.customFields,
        [pageId]: [`${selectedCard}`],
      },
    });
    // analytics flow
    if (selectedCard === 'true') {
      sendAnalyticsData({
        [label]: {
          key: true,
          value: trueValue,
        },
      });
    } else {
      sendAnalyticsData({
        [label]: {
          key: false,
          value: falseValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  return (
    <div className={`boolean-component ${readOnly && 'cursor-disabled'}`}>
      <div className="custom-form-item">
        {!hideLabel && <div className="item-label">{label}</div>}
        {required && <span className="required-mark">*</span>}
        {toolTipChecked && (
          <Tooltip title={toolTipValue}>
            <InfoCircleOutlined className="custom-tooltip" />
          </Tooltip>
        )}
      </div>
      <Card
        bordered={false}
        className={`tile-grid mt-4 ${readOnly && 'pointer-event-none'}`}
      >
        <Card.Grid
          className={`grid-card tile-view ${
            selectedCard === 'false' ? 'card-selected' : ''
          }`}
          hoverable
          onClick={() => {
            setSelectedCard('false');
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e?.key === 'Enter') {
              if (setSelectedCard) {
                setSelectedCard('false');
              }
            }
          }}
        >
          <div className="d-flex flex-vertical align-center">
            <BooleanUnchecked
              selected={selectedCard === 'false'}
              primaryColor={primaryColor}
            />
            <span className="card-title" title={falseValue}>
              {falseValue}
            </span>
          </div>
        </Card.Grid>
        <Card.Grid
          className={`grid-card tile-view ${
            selectedCard === 'true' ? 'card-selected' : ''
          }`}
          hoverable
          onClick={() => {
            setSelectedCard('true');
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e?.key === 'Enter') {
              if (setSelectedCard) {
                setSelectedCard('true');
              }
            }
          }}
        >
          <div className="d-flex flex-vertical align-center">
            <BooleanChecked
              selected={selectedCard === 'true'}
              primaryColor={primaryColor}
            />

            <span className="card-title break-word" title={trueValue}>
              {trueValue}
            </span>
          </div>
        </Card.Grid>
      </Card>
    </div>
  );
};

export default RenderCustomBoolean;
