import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import '../styles/DimensionWidget.less';
import MeasurementTipsModal from './MeasurementTipsModal';
import OtherConfigsForm from './OtherConfigsForm';
import StairsForm from './StairsForm';

const DimensionWidgetComponent = ({
  currentPageSequence = null,
  widgetConfig = null,
  form = null,
  setSavedData,
}) => {
  const {
    state: { storedData },
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const data = { ...storedData };

  useEffect(() => {
    form?.setFieldsValue({
      dimension: data?.dimension || {
        other: {
          manualEntryDepth: 0.41666666,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dimension-wrapper" id="dimensionWrapper">
      {data?.lobObject?.key !== 'STAIRS' ? (
        <>
          <OtherConfigsForm
            widgetConfig={widgetConfig?.common}
            tenantLogo={currentPageSequence?.tenantLogo?.url}
            primaryColor={currentPageSequence?.primaryColor}
            form={form}
            showModal={showModal}
            setShowModal={setShowModal}
            setSavedData={setSavedData}
          />
          {showModal && (
            <MeasurementTipsModal
              showModal={showModal}
              setShowModal={setShowModal}
              widgetConfig={widgetConfig?.common?.measurementTipsManualEntry}
            />
          )}
        </>
      ) : (
        <StairsForm
          widgetConfig={widgetConfig?.stairs}
          form={form}
          setSavedData={setSavedData}
        />
      )}
    </div>
  );
};

export default DimensionWidgetComponent;
