import { Form, Tabs } from 'antd';
import { find } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { isMobileDevice, sendAnalyticsData } from '../../common/utils';
import ManualEntryForm from './ManualEntryForm';
import MapEntryForm from './MapEntryForm';

const OtherConfigsForm = ({
  widgetConfig = null,
  tenantLogo = null,
  primaryColor = '#1890ff',
  form = null,
  setShowModal,
  setSavedData,
}) => {
  const { dispatch } = useContext(AppContext);

  const [selectedTab, setSelectedTab] = useState(
    !isMobileDevice() ? 'MAP_ENTRY' : 'MANUAL_ENTRY',
  );
  const [area, setArea] = useState(0);

  const zipCode = Form?.useWatch('zipCode', form);
  const state = Form?.useWatch('state', form);
  const city = Form?.useWatch('city', form);
  const street = Form?.useWatch('street', form);
  const depthValue = Form?.useWatch(
    ['dimension', 'other', 'manualEntryDepth'],
    form,
  );
  const areaValue = Form?.useWatch(
    ['dimension', 'other', 'manualEntryArea'],
    form,
  );
  const volumeValue = Form?.useWatch(
    ['dimension', 'other', 'manualEntryVolume'],
    form,
  );

  useEffect(() => {
    if (!isMobileDevice()) {
      if (widgetConfig?.manualEntry?.default) {
        setSelectedTab('MANUAL_ENTRY');
      }
      if (widgetConfig?.mapEntry?.default) {
        setSelectedTab('MAP_ENTRY');
      }
    } else {
      setSelectedTab('MANUAL_ENTRY');
    }
  }, [widgetConfig]);

  const items = [
    !isMobileDevice() && {
      key: 'MAP_ENTRY',
      label: widgetConfig?.mapEntry?.title,
      children: (
        <MapEntryForm
          widgetConfig={widgetConfig}
          form={form}
          tenantLogo={tenantLogo}
          primaryColor={primaryColor}
          area={area}
          setArea={setArea}
          setSelectedTab={setSelectedTab}
        />
      ),
    },
    {
      key: 'MANUAL_ENTRY',
      label: widgetConfig?.manualEntry?.title,
      children: (
        <ManualEntryForm
          manualEntry={widgetConfig?.manualEntry}
          form={form}
          setShowModal={setShowModal}
          area={area}
          setSavedData={setSavedData}
        />
      ),
    },
  ];

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({
      zipCode,
      state,
      city,
      street,
      manualEntryDepth: depthValue,
      manualEntryArea: areaValue,
      manualEntryVolume: volumeValue,
      selectedTab: find(items, (item) => item?.key === selectedTab)?.label,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    zipCode,
    state,
    city,
    street,
    depthValue,
    areaValue,
    volumeValue,
    selectedTab,
  ]);

  const handleTabChange = (activeTab) => {
    setSelectedTab(activeTab);
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: activeTab !== 'MAP_ENTRY',
    });
  };

  return (
    <Tabs
      className="dimension-tabs"
      items={items}
      activeKey={selectedTab}
      centered
      onChange={handleTabChange}
    />
  );
};

export default OtherConfigsForm;
