/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  SLUG: '/:slug',
  POSTAL_CODE: '/postal-code',
  CUSTOMER_GOAL: '/customer-goal',
  CURRENT_PRODUCT: '/current-product',
  NEW_PRODUCT: '/new-product',
  QUOTES: '/quotes',
  SCHEDULED_APPOINTMENT: '/scheduled-appointment',
  CONTACT_ME: '/contact-me',
  UPGRADE: '/upgrade',
  THANK_YOU: '/thank-you',
  MAP: '/map',
  CHANGE_QUOTE: '/change-quote',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

/*  Google Upload Action */

export const READ = 'read';
export const WRITE = 'write';

/* Date and time */
export const dateFormat = 'yyyy-MM-DD';

export const CONTACT_NUMBER = '8669288545';
export const EMAIL_ADDRESS = 'contact@drftps.com';
export const SKIP_RECORD = 20;

export const PORTAL_WIDTH = 768;
export const HORIZONTAL_PADDING = 40;

/*  set sub domain while development */
export const SUB_DOMAIN = 'carolinepvt.dev-v3.path.pro';

/* cpq common string */
export const SAME_DAY_TIME = 'to schedule same day service';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  COLOR: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  LOWER_CASE: /[a-z]/g,
  UPPER_CASE: /[A-Z]/g,
  DIGIT: /[0-9]/g,
  SPECIAL_CHAR: /[`!@#$%^&*]/g,
  ZIPCODE: /^[0-9]{5}(?:-[0-9]{4})?$/,
  SLUG: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
};

export const PAGE_LEVEL = {
  POSTAL_CODE: 'POSTAL_CODE',
  CUSTOMER_GOAL: 'CUSTOMER_GOAL',
  CURRENT_PRODUCT: 'CURRENT_WATER_HEATER',
  NEW_PRODUCT: 'NEW_WATER_HEATER',
  UPGRADE_PAGE: 'UPGRADE_PAGE',
  QUOTE_PAGE: 'QUOTE',
  SCHEDULE_PAGE: 'SCHEDULE',
  EMAIL_ME_PAGE: 'EMAIL_ME',
  THANK_YOU: 'THANK_YOU',
  CUSTOMER_INFO: 'CUSTOMER_INFO',
};

export const LEAD_ACTION_DATA = [
  {
    KEY: PAGE_LEVEL?.POSTAL_CODE,
    CONTINUE: 'Customer Provided postal code information.',
    START: 'CPQ was Started by the customer',
  },
  {
    KEY: PAGE_LEVEL?.CUSTOMER_GOAL,
    UPGRADE: 'Customer selected the Upgrade option.',
    BEGIN: 'Customer selected the Repair option.',
    BACK: 'Customer went back to the postal code page.',
    REPLACE: 'Customer selected replace option.',
  },
  {
    KEY: PAGE_LEVEL?.UPGRADE_PAGE,
    CONTINUE: 'Customer selected upgrade options from the list.',
  },
  {
    KEY: PAGE_LEVEL?.NEW_PRODUCT,
    TANK_LESS_SUBMIT: 'Customer submitted the inquiry for Tankless.',
    TANK_LESS_CALL_US: 'Customer selected call us option.',
    GET_QUOTES: 'Customer goes to the Quote page.',
    BACK: 'Customer went back to the Customer goal page.',
  },
  {
    KEY: PAGE_LEVEL?.CURRENT_PRODUCT,
    GET_QUOTES: 'Customer goes to the Quote page.',
    BACK: 'Customer went back to the Customer goal page.',
  },
  {
    KEY: PAGE_LEVEL?.QUOTE_PAGE,
    SELECT: 'Customer selected the quote',
    BACK: 'Customer went back to the Water Heater type page',
  },
  {
    KEY: PAGE_LEVEL?.SCHEDULE_PAGE,
    CARD_OPTION: 'Credit Card Form Submission',
    GET_QUOTES: 'Customer went back to the quote page.',
  },
  {
    KEY: PAGE_LEVEL?.EMAIL_ME_PAGE,
    SELECT: 'Customer send contact information.',
    GET_QUOTES: 'Customer went back to the quote page.',
  },
];

export const DaysOfWeek = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const DYNAMIC_FIELDS_TYPE = {
  PICK_LIST: 'PICK_LIST',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE_TIME: 'DATE_TIME',
  UPLOAD: 'UPLOAD',
  BOOLEAN: 'BOOLEAN',
  ENUM_PICK_LIST: 'ENUM_PICK_LIST',
};

export const DEFAULT_STAIRS_DEPTH_OPTIONS = [
  {
    label: '3 Inch (0.25 Feet)',
    value: 0.25,
  },
  {
    label: '4 Inch (0.33 Feet)',
    value: 0.33,
  },
  {
    label: '5 Inch',
    value: 0.41666666,
  },
  {
    label: '6 Inch (0.5 Feet)',
    value: 0.5,
  },
];
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_VALIDATION = {
  ALLOW_PAST: 'ALLOW_PAST',
  ALLOW_FUTURE: 'ALLOW_FUTURE',
};

export const CONDITIONS_KEYS = {
  EQUAL_TO: 'EQUAL_TO',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  GREATER_LESS_RANGE: 'GREATER_LESS_RANGE',
};

export const staticFieldKeys = {
  ALLOW_PRICE_CHANGE: {
    key: 'allowPriceChange',
    label: 'Allow Price Change',
  },
  ASSET: {
    key: 'asset',
    label: 'Asset',
  },
  MANAGE_INVENTORY: {
    key: 'manageInventory',
    label: 'Manage Inventory',
  },
  LABOUR_WARRANTY: {
    key: 'labourWarranty',
    label: 'Labour Warranty',
  },
  PRODUCT_WARRANTY: { key: 'productWarranty', label: 'Product Warranty' },
  PRODUCT_CATEGORY: { key: 'productCategory', label: 'Product Category' },
  UNIT_OF_MEASURE: { key: 'unitOfMeasure', label: 'Unit Of Measure' },
  STOCK: { key: 'stock', label: 'Stock' },
  MANUFACTURER: { key: 'manufacturer', label: 'Manufacturer' },
};

export const PRIMARY_COLOR = '#1890ff';

export const WIDGET_TYPES = {
  CONTENT: 'CONTENT',
  SUPPORT: 'SUPPORT',
  PROJECT: 'PROJECT',
  POSTAL: 'POSTAL',
  CONTACT: 'CONTACT',
  APPOINTMENT: 'APPOINTMENT',
  QUOTES: 'QUOTES',
  CART: 'CART',
  CUSTOM: 'CUSTOM',
  SYSTEM: 'SYSTEM',
  ADD_ON: 'ADDON',
  CUSTOMER_INFO: 'CUSTOMER_INFO',
};

export const PAGE_KEYS = {
  POSTAL_CODE: 'postal-code',
};
// need to change this to SYSTEM AND CUSTOM
export const FIELD_CATEGORY = {
  SYSTEM: 'SYSTEM',
  CUSTOM: 'CUSTOM',
};

export const RESERVE_APPOINTMENT_FROM = {
  CARD: 'CARD',
  CONTACT: 'CONTACT',
};

export const CUBIC_YARD_CONVERSION_RATIO = 27;
export const WATER_HEATER_KEY = 'LOB_WATER_HEATER';

export const SPONSOR_KEY = 'SPONSOR';
export const TENANT_KEY = 'TENANT';
