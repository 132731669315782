import { RightOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Col, Divider, Form, Row } from 'antd';
import { find, isArray, lowerCase, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../AppContext';
import { sendAnalyticsData } from '../common/utils';
import { GET_ADD_ON_PRODUCTS } from '../graphql/Query';
import InputComponent from './InputComponent';
import LoaderComponent from './LoaderComponent';
import NumberComponent from './NumberComponent';
import { qualityOptions } from './QuoteWidgetComponent';
import './styles/AddOnComponent.less';

const RenderQuantity = ({ item }) => (
  <Row key={item?.id} gutter={20} className="mb-16">
    <Col span={2} className="d-flex justify-center align-center">
      <Form.Item
        className="d-flex justify-center align-center m-0"
        name={['addOnProducts', item?.id, 'selected']}
        valuePropName="checked"
        initialValue={item?.selected ?? false}
      >
        <Checkbox />
      </Form.Item>
    </Col>
    <Col span={18} className="d-flex justify-center align-center product-title">
      {item?.name}
    </Col>
    <Col span={4} className="d-flex justify-center align-center">
      <Form.Item
        className="d-flex justify-center align-center m-0"
        initialValue={item?.quantity ?? 1}
        name={['addOnProducts', item?.id, 'quantity']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue(['addOnProducts', item?.id, 'selected'])) {
                if (!value) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise?.reject(`Please Enter Quantity`);
                }
                return Promise?.resolve();
              }
              return Promise?.resolve();
            },
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                value &&
                getFieldValue(['addOnProducts', item?.id, 'selected'])
              ) {
                const convertedToInt = parseFloat(value, 10);
                if (value < 0) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise?.reject(`Quantity can not be negative`);
                }
                if (item?.minimumQuantity && item?.maximumQuantity) {
                  if (
                    !(
                      convertedToInt >= item?.minimumQuantity &&
                      convertedToInt <= item?.maximumQuantity
                    )
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      `should be between ${item?.minimumQuantity} to ${item?.maximumQuantity}`,
                    );
                  }
                } else if (item?.minimumQuantity && !item?.maximumQuantity) {
                  if (!(convertedToInt >= item?.minimumQuantity)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      `should be greater than ${item?.minimumQuantity}`,
                    );
                  }
                } else if (item?.maximumQuantity && !item?.minimumQuantity) {
                  if (!(convertedToInt <= item?.maximumQuantity)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      `should be less than ${item?.maximumQuantity}`,
                    );
                  }
                }
              }
              return Promise?.resolve();
            },
          }),
        ]}
      >
        <NumberComponent
          className="d-flex justify-center align-center"
          placeholder={0}
          width={10}
        />
      </Form.Item>
    </Col>
  </Row>
);

const AddOnComponent = ({ widgetConfig = null, setSavedData, form }) => {
  const {
    state: { storedData },
  } = useContext(AppContext);
  const {
    zipCode,
    productId,
    quoteVariant,
    navigateTo,
    selectedTab,
  } = storedData;
  const {
    allowDuplicateProductsFromPackage,
    customerCanSkipPage = false,
    minProductsToOffer: min,
    maxProductsToOffer: max,
  } = widgetConfig;
  const addOnProductsSelected = Form?.useWatch(['addOnProducts'], form);

  const isAddOnSelected = useMemo(
    () =>
      customerCanSkipPage ||
      !!Object.keys(addOnProductsSelected ?? {})
        ?.map((item) => addOnProductsSelected?.[item]?.selected)
        .find((item) => item),
    [addOnProductsSelected, customerCanSkipPage],
  );

  useEffect(() => {
    form?.setFieldValue(['addOnProducts', 'validation'], !isAddOnSelected);
  }, [isAddOnSelected, form]);

  const [
    addOnProductSuggestionsData,
    setAddOnProductSuggestionsData,
  ] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = map(addOnProductsSelected, (item, id) => ({
      ...item,
      name: find(addOnProductSuggestionsData, (product) => product?.id === id)
        ?.name,
    }));
    sendAnalyticsData({ addonData: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOnProductsSelected]);

  const [addOnProducts] = useLazyQuery(GET_ADD_ON_PRODUCTS, {
    onCompleted: (res) => {
      if (
        res &&
        res.addOnProducts &&
        res.addOnProducts.data &&
        res.addOnProducts.data.length > 0
      ) {
        if (storedData?.addOnProducts) {
          setAddOnProductSuggestionsData(() =>
            res.addOnProducts.data?.map((item) => {
              const existingAddOnProduct = storedData?.addOnProducts?.find(
                (items) => items?.id === item?.id,
              );
              return {
                ...item,
                quantity: existingAddOnProduct?.quantity,
                selected: !!existingAddOnProduct,
              };
            }),
          );
        } else {
          setAddOnProductSuggestionsData(res?.addOnProducts?.data);
        }
        setSavedData((prev) => ({
          ...prev,
          products: {
            ...storedData?.products,
            [lowerCase(storedData?.quoteVariant)]: {
              ...storedData?.products?.[lowerCase(storedData?.quoteVariant)],
              addOnProductItemInfo: res?.addOnProducts?.data,
            },
          },
        }));
      }
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (addOnProductSuggestionsData) {
      setSavedData({
        ...storedData,
        products: {
          ...storedData?.products,
          [lowerCase(storedData?.quoteVariant)]: {
            ...storedData?.products?.[lowerCase(storedData?.quoteVariant)],
            addOnProductItemInfo: addOnProductSuggestionsData,
          },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSavedData, storedData]);
  useEffect(() => {
    if (zipCode && productId && quoteVariant && navigateTo) {
      addOnProducts({
        variables: {
          where: {
            packageId: productId,
            quality: qualityOptions?.[selectedTab],
            questionnaireId: navigateTo,
            zipCode,
            allowProductsFromPackage: allowDuplicateProductsFromPackage,
            min: parseFloat(min),
            max: parseFloat(max),
          },
        },
      });
    }
  }, [
    addOnProducts,
    zipCode,
    productId,
    quoteVariant,
    navigateTo,
    allowDuplicateProductsFromPackage,
    selectedTab,
    min,
    max,
  ]);

  const handleRedirect = () => {
    form?.resetFields();
    form?.submit();
  };

  return (
    <div className="content-page add-on-container">
      <div className="mt-37">
        {widgetConfig?.title && widgetConfig?.title?.length > 0 && (
          <div className="bold-label">
            <span>{widgetConfig?.title}</span>
          </div>
        )}
        {widgetConfig?.subtitle && widgetConfig?.subtitle?.length > 0 && (
          <div className="bold-label mt-4 content-font-size">
            <span>{widgetConfig?.subtitle}</span>
          </div>
        )}
        {widgetConfig?.description && widgetConfig?.description?.length > 0 && (
          <div className="bold-label mt-4 content-font-size">
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: widgetConfig?.description,
              }}
            />
          </div>
        )}
      </div>
      <Divider className="title-divider" />
      <div>
        <LoaderComponent spinning={loading} setHeight={20}>
          {isArray(addOnProductSuggestionsData) &&
          addOnProductSuggestionsData?.length > 0 ? (
            <>
              <Row gutter={20} className="mb-16 mt-16">
                <Col span={2} />
                <Col span={18} className="title-text">
                  Name
                </Col>
                <Col span={4} className="title-text">
                  Quantity
                </Col>
              </Row>
              <Divider className="title-divider" />
              {addOnProductSuggestionsData?.map((item) => (
                <RenderQuantity
                  customerCanSkipPage={!customerCanSkipPage}
                  key={item?.id}
                  item={item}
                  form={form}
                />
              ))}
              <Form.Item
                name={['addOnProductsValidation']}
                rules={[
                  {
                    required: !isAddOnSelected,
                    message:
                      'Please Enter At Least One Add On Product To Continue',
                  },
                ]}
                className={!isAddOnSelected ? 'mb-16' : ''}
              >
                <InputComponent className="d-none" />
              </Form.Item>
            </>
          ) : (
            <div className="mx-12 thin-labe">No Add on Products Found</div>
          )}
        </LoaderComponent>
        {customerCanSkipPage && (
          <div className="fill-width d-flex justify-end">
            <Button
              type="link"
              className="contact-me-btn skip-add-on-btn d-flex"
              onClick={handleRedirect}
              icon={<RightOutlined className="arrow" />}
            >
              Skip
            </Button>
          </div>
        )}
      </div>
      {widgetConfig?.footerBlock?.enable && (
        <div className="mt-37">
          {widgetConfig?.footerBlock?.titleCheck &&
            widgetConfig?.footerBlock?.titleText?.length > 0 && (
              <div className="bold-label">
                <span>{widgetConfig?.footerBlock?.titleText}</span>
              </div>
            )}
          {widgetConfig?.footerBlock?.subTitleCheck &&
            widgetConfig?.footerBlock?.subTitleText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <span>{widgetConfig?.footerBlock?.subTitleText}</span>
              </div>
            )}
          {widgetConfig?.footerBlock?.descriptionCheck &&
            widgetConfig?.footerBlock?.descriptionText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <div
                  className="editor-render"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: widgetConfig?.footerBlock?.descriptionText,
                  }}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default AddOnComponent;
