import {
  DeleteOutlined,
  InfoCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, Empty, Popconfirm, Popover } from 'antd';
import { isEmpty, kebabCase, lowerCase, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { WIDGET_TYPES } from '../common/constants';
import {
  createLeadTracking,
  formatPrice,
  googleAnalyticsParams,
  sendAnalyticsData,
  updateDataMutation,
} from '../common/utils';
import { DELETE_PROJECT } from '../graphql/Mutation';
import LoaderComponent from './LoaderComponent';
import './styles/MyCartWidgetComponent.less';

export const getAddOnProducts = (product) =>
  product?.data?.products?.[
    lowerCase(product?.data?.quoteVariant)
  ]?.addOnProductItemInfo
    ?.map((item) => {
      const addOnResponse = product?.data?.addOnProducts?.find(
        (items) => items?.id === item?.id,
      );
      return {
        ...item,
        quantity: addOnResponse?.quantity,
        totalPrice: addOnResponse?.quantity * item?.defaultPrice,
        selected: !!addOnResponse,
      };
    })
    ?.filter((item) => item?.selected);
const MyCartWidgetComponent = ({
  widgetConfig = null,
  pageSequencesData = [],
  currentPageSequence = null,
  finalProjectData = [],
  refetchLeadStoreData,
}) => {
  const {
    dispatch,
    state: { storedData, projectLoading, subDomain },
  } = useContext(AppContext);
  const currentProject = { ...storedData };

  const filteredProductData = useMemo(() => finalProjectData?.filter((project) => project?.data?.productTitle), [finalProjectData]);

  const location = useLocation();
  const navigate = useNavigate();
  const [showAddNewProjectBtn, setShowAddNewProjectBtn] = useState(true);

  useEffect(() => {
    // Using a timeout to ensure the Popconfirm is rendered
    const interval = setInterval(() => {
      // commented for now -> as we also can achieve focus functionality using query selection
      // eslint-disable-next-line no-undef
      // const okButton = document?.querySelector(
      //   '.ant-popover-buttons .ant-btn-primary'
      // );
      // // eslint-disable-next-line no-undef
      // const cancelButton = document?.querySelector(
      //   '.ant-popover-buttons .ant-btn'
      // );

      // eslint-disable-next-line no-undef
      const okButton = document?.getElementById('ok-btn');
      // eslint-disable-next-line no-undef
      const cancelButton = document?.getElementById('cancel-btn');

      if (okButton) {
        okButton?.setAttribute('tabindex', '2');
        okButton?.removeAttribute('autoFocus');
        clearInterval(interval);
      }
      if (cancelButton) {
        cancelButton?.setAttribute('tabindex', '3');
        cancelButton?.removeAttribute('autoFocus');
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    onCompleted() {
      refetchLeadStoreData();
    },
    onError() {
      dispatch({
        type: 'SET_PROJECT_LOADING',
        data: false,
      });
    },
  });

  useEffect(() => {
    async function updateData({ data }) {
      // eslint-disable-next-line no-undef
      const wsmTracker = window?.Wsm?.getAsyncTracker();
      const visitorId = await wsmTracker?.getVisitorUniqueId();
      await updateDataMutation(
        { ...data, currentQuote: true },
        dispatch,
        visitorId,
      );
    }

    const prevProject = currentProject;
    if (isEmpty(currentProject?.lobObject)) {
      const getPreviousData = finalProjectData?.find(
        (item) => !isEmpty(item?.data?.lobObject),
      );

      if (getPreviousData) {
        updateData(getPreviousData);

        if (prevProject?.id) {
          deleteProject({
            variables: {
              where: {
                id: prevProject?.id,
              },
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalProjectData]);

  // in below useEffect we are verifying that if user passes the lob and sa in URL and based on that we are hiding ADD New Project button
  // if user passes the lob and sa in URL that means don't show ADD New Project button and vice versa
  useEffect(() => {
    if (!isEmpty(storedData?.urlParams)) {
      setShowAddNewProjectBtn(
        !!(!storedData?.urlParams?.lob && !storedData?.urlParams?.sa),
      );
    }
    if (storedData?.visitorId) {
      setShowAddNewProjectBtn(false);
    }
    // normal flow -> follow usual flow
    // change quote -> hide delete button,add new project btn
    // skip page flow->
    // 	1) if have project widget then show 'add new project btn' and delete button (user can change quote by clicking 'change quote' button)
    // 	2) doesn't have project widget then hide 'add new project btn' and delete button (user can change quote by clicking 'change quote' button)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: finalProjectData?.length !== 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProductData]);

  useEffect(() => {
    // We have entry of products that doesn't have quote info, so need to replace currentQuote with the quote detail object for preventing error on back.
    refetchLeadStoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({
      packageData: map(filteredProductData, (item) => ({
        selectedTab: item?.data?.selectedTab,
        quoteVariant: item?.data?.quoteVariant,
        packageTitle: item?.data?.productTitle,
        packagePrice: item?.data?.productPrice,
      })),
    });
  }, [filteredProductData]);

  const handleAddNewProject = async () => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    // for add new project button log visit track, need to set ping true for add new project button as discussed with sagar sir.
    await wsmTracker?.setIsBackPing(true);
    await wsmTracker?.trackPageView();
    dispatch({
      type: 'SET_PROJECT_LOADING',
      data: true,
    });
    const projectPage = pageSequencesData?.find(
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.PROJECT,
    );

    if (filteredProductData?.length === 0) {
      // add condition for last project deletion
      return navigate(
        `/${currentProject?.slug}/${kebabCase(
          projectPage?.pageKey,
        )}${googleAnalyticsParams(location?.state?.urlParams)}`,
      );
    }
    const oldProjectObj = {
      ...storedData,
      currentQuote: false,
    };

    await updateDataMutation(oldProjectObj, dispatch, visitorId);

    const newProjectObj = {
      industryId: currentProject?.industryId,
      leadId: storedData?.leadId,
      mobileNumber: currentProject?.mobileNumber,
      city: currentProject?.city,
      street: currentProject?.street,
      email: currentProject?.email,
      firstName: currentProject?.firstName,
      lastName: currentProject?.lastName,
      currentQuote: true,
      lat: currentProject?.lat,
      lng: currentProject?.lng,
      zipCode: currentProject?.zipCode,
      previousPageKey: currentProject?.pageKey,
      currentOrder: currentProject?.currentOrder,
      shortAddress: currentProject?.shortAddress,
      slug: currentProject?.slug,
      isZipCodeValid: currentProject?.isZipCodeValid,
      subDomain,
      state: currentProject?.state,
    };

    await updateDataMutation(newProjectObj, dispatch, visitorId);

    if (storedData?.leadId) {
      const { slug = null } = storedData;
      const pageKey = currentPageSequence?.pageKey;
      const action = 'user clicked on add new project button';

      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }
    dispatch({
      type: 'SET_PROJECT_LOADING',
      data: false,
    });
    navigate(
      `/${currentProject?.slug}/${kebabCase(
        projectPage?.pageKey,
      )}${googleAnalyticsParams(location?.state?.urlParams)}`,
      {
        state: location?.state,
      },
    );
  };

  const handleDeleteProject = async (id) => {
    dispatch({
      type: 'SET_PROJECT_LOADING',
      data: true,
    });
    const storeDataClone = { ...storedData };

    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    const newProjectObj = {
      industryId: currentProject?.industryId,
      leadId: storedData?.leadId,
      mobileNumber: currentProject?.mobileNumber,
      city: currentProject?.city,
      currentQuote: true,
      currentOrder: currentPageSequence?.currentOrder,
      currentPage: currentPageSequence?.pageKey,
      lat: currentProject?.lat,
      lng: currentProject?.lng,
      zipCode: currentProject?.zipCode,
      shortAddress: currentProject?.shortAddress,
      slug: currentProject?.slug,
      isZipCodeValid: currentProject?.isZipCodeValid,
      subDomain,
      state: currentProject?.state,
    };
    if (filteredProductData?.length === 1) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false,
      });
      await updateDataMutation(newProjectObj, dispatch, visitorId);
    } else if (storeDataClone?.id === id) {
      // if user removing current project then we are storing a last created project in our storeData
      const lastCreatedProject = finalProjectData?.findLast(
        (project) => project?.id !== id,
      )?.data;

      if (!isEmpty(lastCreatedProject)) {
        await updateDataMutation(lastCreatedProject, dispatch, visitorId);
      } else {
        dispatch({
          type: 'SET_SHOW_SUBMIT_BTN',
          data: false,
        });

        await updateDataMutation(newProjectObj, dispatch, visitorId);
      }
    }

    deleteProject({
      variables: {
        where: {
          id,
        },
      },
    });
  };

  const handleChangeQuote = async (projectId) => {
    // eslint-disable-next-line no-undef
    const wsmTracker = await window?.Wsm?.getAsyncTracker();
    const visitorId = await wsmTracker?.getVisitorUniqueId();

    // for add change quote button log visit track, need to set ping true for add change quote button as discussed with sagar sir.
    await wsmTracker?.setIsBackPing(true);
    await wsmTracker?.trackPageView();

    const quotePage = pageSequencesData?.find(
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.QUOTES,
    );

    if (storedData?.leadId) {
      const { slug = null } = storedData;
      const pageKey = currentPageSequence?.pageKey;
      const action = 'user clicked on change quote button';

      createLeadTracking(slug, pageKey, visitorId, storedData?.leadId, action);
    }

    // finding a page key of previous page of quote page
    const indexOfPageBeforeQuotePage = pageSequencesData?.findIndex(
      (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.QUOTES,
    );
    const pageBeforeQuotePage =
      pageSequencesData?.[indexOfPageBeforeQuotePage - 1];

    const selectedProject = finalProjectData?.find(
      (project) => project?.id === projectId,
    )?.data;

    const newProject = {
      ...selectedProject,
      currentQuote: true,
      previousPageKey: pageBeforeQuotePage?.pageKey,
      previousPageTitle: pageBeforeQuotePage?.title,
    };

    if (projectId !== storedData?.id) {
      const oldProjectObj = {
        ...storedData,
        currentQuote: false,
        previousPageKey: pageBeforeQuotePage?.pageKey,
        previousPageTitle: pageBeforeQuotePage?.title,
      };
      await updateDataMutation(oldProjectObj, dispatch, visitorId);
    }
    await updateDataMutation(newProject, dispatch, visitorId);
    navigate(
      `/${currentProject?.slug}/${kebabCase(
        quotePage?.pageKey,
      )}${googleAnalyticsParams(location?.state?.urlParams)}`,
      {
        state: location?.state,
      },
    );
  };

  let total = 0;

  return (
    <div className="my-cart-wrapper">
      <LoaderComponent spinning={projectLoading} setHeight={10}>
        {map(filteredProductData, (project) => {
          total += parseFloat(project?.data?.productPrice);
          const addOnProducts = getAddOnProducts(project);
          const addOnProductsName = [];
          let totalAddOnProduct = 0;
          addOnProducts?.map((item) => {
            total += parseFloat(item?.totalPrice);
            addOnProductsName?.push(`${item?.quantity} x ${item?.name}`);
            totalAddOnProduct += parseFloat(item?.totalPrice);
            return null;
          });
          return (
            <div key={project?.id}>
              <div className="fill-width d-flex justify-between align-center change-quote-section">
                <span className="bold-label package-title-quote-variant">
                  {project?.data?.productTitle}: {project?.data?.quoteVariant}
                </span>
                <div className="amount-section">
                  {formatPrice(project?.data?.productPrice)}
                </div>
              </div>

              {totalAddOnProduct > 0 && (
                <div className="fill-width d-flex justify-between align-center change-quote-section add-on-product">
                  <span className="bold-label add-on-title-quote-variant">
                    Add On Products
                    <Popover
                      trigger="hover"
                      content={map(addOnProductsName, (items, i) => (
                        <p key={`${items}-${i}`}>{items}</p>
                      ))}
                      overlayClassName="common-tooltip"
                      placement="bottom"
                    >
                      <InfoCircleOutlined className="info-icon ml-4" />
                    </Popover>
                  </span>
                  <div className="amount-section">
                    {formatPrice(totalAddOnProduct)}
                  </div>
                </div>
              )}
              {/* collapse with description code goes here */}
              <div className="fill-width d-flex justify-between align-center change-quote-section">
                <Button
                  type="link"
                  icon={<LeftOutlined className="arrow" />}
                  className="change-my-quote-btn"
                  onClick={() => handleChangeQuote(project?.id)}
                >
                  {widgetConfig?.changeQuoteButtonLabel}
                </Button>
                {/* user can't delete a last product from cart page because  if user delete all products it will remove all leadStore data.
                 so that we doesn't have any user choice stored in leadstore.
                  so we disabled delete button and displayed tooltip  */}
                {!showAddNewProjectBtn ? (
                  <Popover
                    placement="topRight"
                    content="You may change the quote, but you cannot remove all items from the cart."
                    overlayClassName="delete-button-tooltip"
                  >
                    <Button
                      disabled
                      tabIndex={0}
                      icon={<DeleteOutlined />}
                      danger
                    />
                  </Popover>
                ) : (
                  <Popconfirm
                    title="Are you sure you want to remove this project"
                    onConfirm={() => handleDeleteProject(project?.id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ autoFocus: false, id: 'ok-btn' }}
                    cancelButtonProps={{ autoFocus: true, id: 'cancel-btn' }}
                  >
                    <Button
                      className="delete-quote-btn"
                      tabIndex={0}
                      icon={<DeleteOutlined />}
                      danger
                    />
                  </Popconfirm>
                )}
              </div>
              <Divider className="cart-divider" />
            </div>
          );
        })}

        {filteredProductData?.length > 0 && (
          <>
            <div className="d-flex flex-vertical align-end total-amount-section">
              <span>Total</span>
              <span className="amount">{formatPrice(total)}</span>
            </div>
          </>
        )}
        {filteredProductData?.length <= 0 && !projectLoading && (
          <Empty className="empty-project" description="No Project Found!" />
        )}
        {showAddNewProjectBtn && (
          <>
            <div>
              <Button
                onClick={handleAddNewProject}
                className="fill-width mt-15 add-new-project"
              >
                {widgetConfig?.addProjectButtonLabel}
              </Button>
            </div>
            <Divider className="cart-divider" />
          </>
        )}
      </LoaderComponent>
    </div>
  );
};

export default MyCartWidgetComponent;
