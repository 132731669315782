import React, { useState } from 'react';
import { PRIMARY_COLOR } from '../../common/constants';
import ModalComponent from '../ModalComponent';
import GooglMapComponent from './GooglMapComponent';

const HightYourAreaModal = ({
  shortAddress = null,
  tenantLogo = null,
  primaryColor = PRIMARY_COLOR,
  modalConfig = null,
  showHighLightYourAreaModal = false,
  setShowHighLightYourAreaModal,
  measurementTipsModalConfig = null,
  area = 0,
  setArea,
  setSelectedTab,
}) => {
  const [showTips, setShowTips] = useState(false);
  return (
    <>
      <ModalComponent
        width={600}
        open={showHighLightYourAreaModal}
        setOpen={setShowHighLightYourAreaModal}
        onCancel={() => setShowHighLightYourAreaModal(false)}
        footer={null}
        destroyOnClose
        wrapClassName="highlight-your-area-modal"
        closable={false}
      >
        <GooglMapComponent
          modalConfig={modalConfig}
          shortAddress={shortAddress}
          tenantLogo={tenantLogo}
          primaryColor={primaryColor}
          setShowHighLightYourAreaModal={setShowHighLightYourAreaModal}
          showTips={showTips}
          setShowTips={setShowTips}
          measurementTipsModalConfig={measurementTipsModalConfig}
          area={area}
          setArea={setArea}
          setSelectedTab={setSelectedTab}
        />
      </ModalComponent>
    </>
  );
};

export default HightYourAreaModal;
