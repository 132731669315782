import React, { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';

const ContentWidgetComponent = ({
  widgetConfig = null,
  setSavedData,
  form,
  isLastPage = false,
}) => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => 
     () => {
      if (isLastPage) {
        dispatch({
          type: 'SET_TOTAL_PROJECT_COUNT',
          data: 0,
        });

        dispatch({
          type: 'SET_STORED_DATA',
          data: {},
        });
        setSavedData(null);
        form?.resetFields();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [isLastPage]);

  return (
    <div className="content-page">
      {widgetConfig?.headerBlock?.enable && (
        <div className="mt-37">
          {widgetConfig?.headerBlock?.titleCheck &&
            widgetConfig?.headerBlock?.titleText?.length > 0 && (
              <div className="bold-label">
                <span>{widgetConfig?.headerBlock?.titleText}</span>
              </div>
            )}
          {widgetConfig?.headerBlock?.subTitleCheck &&
            widgetConfig?.headerBlock?.subTitleText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <span>{widgetConfig?.headerBlock?.subTitleText}</span>
              </div>
            )}
          {widgetConfig?.headerBlock?.descriptionCheck &&
            widgetConfig?.headerBlock?.descriptionText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <div
                  className="editor-render d-flex justify-center flex-vertical"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: widgetConfig?.headerBlock?.descriptionText,
                  }}
                />
              </div>
            )}
        </div>
      )}
      {widgetConfig?.footerBlock?.enable && (
        <div className="mt-37">
          {widgetConfig?.footerBlock?.titleCheck &&
            widgetConfig?.footerBlock?.titleText?.length > 0 && (
              <div className="bold-label">
                <span>{widgetConfig?.footerBlock?.titleText}</span>
              </div>
            )}
          {widgetConfig?.footerBlock?.subTitleCheck &&
            widgetConfig?.footerBlock?.subTitleText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <span>{widgetConfig?.footerBlock?.subTitleText}</span>
              </div>
            )}
          {widgetConfig?.footerBlock?.descriptionCheck &&
            widgetConfig?.footerBlock?.descriptionText?.length > 0 && (
              <div className="bold-label mt-4 content-font-size">
                <div
                  className="editor-render d-flex justify-center flex-vertical"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: widgetConfig?.footerBlock?.descriptionText,
                  }}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default ContentWidgetComponent;
