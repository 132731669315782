import { Button, Col, Form, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import DimensionMapMarker from '../../assets/svg/dimension-map-marker.svg';
import InputComponent from '../InputComponent';
import NumberComponent from '../NumberComponent';
import SelectComponent from '../SelectComponent';
import HightYourAreaModal from './HightYourAreaModal';

const { Option } = SelectComponent;

const MapEntryForm = ({
  widgetConfig = null,
  form = null,
  tenantLogo = null,
  primaryColor = '#1890ff',
  area = 0,
  setArea,
  setSelectedTab,
}) => {
  const {
    state: { storedData },
    dispatch,
  } = useContext(AppContext);

  const [showHighLightYourAreaModal, setShowHighLightYourAreaModal] = useState(
    false,
  );
  const [shortAddress, setShortAddress] = useState(null);
  const [showContinueBtn, setShowContinueBtn] = useState(false);
  const street = Form?.useWatch('street', form);

  useEffect(() => {
    const currentQuoteObj = { ...storedData };
    if (currentQuoteObj) {
      form?.setFieldsValue({
        zipCode: currentQuoteObj?.zipCode,
        state: currentQuoteObj?.state,
        city: currentQuoteObj?.city,
        street: currentQuoteObj?.street,
      });
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowContinueBtn(street);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [street]);

  const getLatLng = () => {
    const currentQuoteObj = { ...storedData };

    const shortAddressCopy = currentQuoteObj?.shortAddress;
    const postalCode = currentQuoteObj?.zipCode;

    const address = `${street}, ${shortAddressCopy} ${postalCode}, USA`;
    setArea(0);
    setShortAddress(address);
    setShowHighLightYourAreaModal(true);
  };

  return (
    <div>
      {widgetConfig?.mapEntry?.mapCheck && (
        <div className="map-entry-section">
          <img
            src={DimensionMapMarker}
            alt="import-icon"
            width={130}
            height={130}
          />
        </div>
      )}
      {showHighLightYourAreaModal && (
        <HightYourAreaModal
          shortAddress={shortAddress}
          tenantLogo={tenantLogo}
          primaryColor={primaryColor}
          modalConfig={widgetConfig?.highlightYourArea}
          measurementTipsModalConfig={widgetConfig?.measurementTipsMapEntry}
          showHighLightYourAreaModal={showHighLightYourAreaModal}
          setShowHighLightYourAreaModal={setShowHighLightYourAreaModal}
          area={area}
          setArea={setArea}
          setSelectedTab={setSelectedTab}
        />
      )}
      <Form.Item name="street">
        <InputComponent allowClear placeholder="Street*" />
      </Form.Item>
      <Form.Item name="state">
        <SelectComponent placeholder="State*" disabled>
          {map([], (state) => (
            <Option key={state?.id} value={state?.name}>
              {state?.name}
            </Option>
          ))}
        </SelectComponent>
      </Form.Item>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name="city">
            <SelectComponent placeholder="City*" disabled>
              {map([], (city) => (
                <Option key={city?.id} value={city?.name}>
                  {city?.name}
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="zipCode">
            <NumberComponent disabled allowClear placeholder="Postal Code*" />
          </Form.Item>
        </Col>
      </Row>
      {showContinueBtn && (
        <Button
          className="common-button"
          id="submit-btn"
          type="primary"
          onClick={getLatLng}
        >
          {widgetConfig?.mapEntry?.actionButtonLabelText}
        </Button>
      )}
    </div>
  );
};

export default MapEntryForm;
