import { Button } from 'antd';
import React from 'react';
import ModalComponent from '../ModalComponent';

const MeasurementTipsModal = ({
  showModal = false,
  setShowModal,
  widgetConfig = null,
}) => (
    <>
      <ModalComponent
        open={showModal}
        setOpen={setShowModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        destroyOnClose
        wrapClassName="measurement-tips-modal"
        closable={false}
      >
        <div className="measurement-tip-wrapper">
          <div className="title">{widgetConfig?.titleText}</div>
          <div
            className="tips-content editor-render"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: widgetConfig?.descriptionText || '-',
            }}
          />
          <div className="cta-button mt-10">
            <Button
              className="common-button"
              type="primary"
              onClick={() => setShowModal(false)}
            >
              {widgetConfig?.actionButtonLabel}
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );

export default MeasurementTipsModal;
