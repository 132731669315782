import {
  ArrowDownOutlined,
  CheckOutlined,
  CreditCardOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Popover,
  Row,
} from 'antd';
import locale from 'antd/es/locale/en_US';
import {
  find,
  forEach,
  groupBy,
  isEmpty,
  lowerCase,
  map,
  upperCase,
} from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ICalendarLink from 'react-icalendar-link';
import { PatternFormat } from 'react-number-format';
import { AppContext } from '../AppContext';
import { CalendarDates, LocationSecondary } from '../assets/svg';
import { DaysOfWeek, REGEX, dateFormat } from '../common/constants';
import {
  formValidatorRules,
  formatPhoneNumberWithoutMask,
  formatPrice,
  sendAnalyticsData,
} from '../common/utils';
import CardSection from './CardSection';
import InputComponent from './InputComponent';
import LoaderComponent from './LoaderComponent';
import { getAddOnProducts } from './MyCartComponent';
import NumberComponent from './NumberComponent';
import SelectComponent from './SelectComponent';
import './styles/AppointmentWidgetComponent.less';

const {
  zipCode,
  required,
  email,
  requiredWhiteSpaceAllowed,
} = formValidatorRules;

const { Option } = SelectComponent;

const { Panel } = Collapse;

const checkIfSlotIsAlreadyExists = (timeSlots, slotKey, date) => {
  if (
    timeSlots?.some(
      (item) => item?.label === slotKey?.label && item?.date === date,
    )
  ) {
    return true;
  }
  return false;
};

const AppointmentWidgetComponent = ({
  widgetConfig = null,
  currentPageSequence = null,
  form = null,
  setSavedData,
  finalProjectData = [],
  refetchLeadStoreData,
}) => {
  const {
    state: { storedData, projectLoading },
    dispatch,
  } = useContext(AppContext);

  const [locationObj, setLocationObj] = useState({
    completed: false,
    visible: true,
  });
  const [timeObj, setTimeObj] = useState({
    completed: false,
    visible: false,
  });
  const [appointmentObj, setAppointmentObj] = useState({
    completed: false,
    visible: false,
  });

  const [appointmentOpenOnce, setAppointmentOpenOnce] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [geoAddress, setGeoAddress] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState({
    timeSlots: [],
  });
  const [selectedStep, setSelectedStep] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [weekData, setWeekData] = useState([]);
  const [activeCollapseKey, setActiveCollapseKey] = useState([]);

  const [holidayTitle, setHolidayTitle] = useState('');
  const [holidayMessage, setHolidayMessage] = useState('');
  const [analyticsData, setAnalyticsData] = useState(null);

  const street = Form?.useWatch('street', form);
  const stateValue = Form?.useWatch('state', form);
  const cityValue = Form?.useWatch('city', form);
  const zipCodeValue = Form?.useWatch('zipCode', form);
  const emailValue = Form?.useWatch('email', form);
  const firstName = Form?.useWatch('firstName', form);
  const lastName = Form?.useWatch('lastName', form);
  const comments = Form?.useWatch('comments', form);
  const mobileNumber = Form?.useWatch('mobileNumber', form);
  const contactMethods = Form?.useWatch('contactMethods', form);
  const cardNumber = Form?.useWatch('cardNumber', form);
  const cardExpiry = Form?.useWatch('cardExpiry', form);
  const cardCvc = Form?.useWatch('cardCvc', form);

  const timeSlotActive = currentPageSequence?.appointmentWindow?.active;

  const getEvent = () => {
    const event = {
      title: currentPageSequence?.appointmentWindow?.showTime
        ? `${selectedTime?.label} ${moment(selectedTime?.from)?.format('LT')} -
      ${moment(selectedTime?.to)?.format('LT')}`
        : selectedTime?.label,
      ...(timeSlotActive
        ? {
            startTime: moment(
              `${moment(selectedDate)?.format(dateFormat)} ${moment(
                selectedTime?.from || moment()?.startOf('day'),
              )?.format('HH:mm')}`,
              'YYYY-MM-DD HH:mm',
            ),
            endTime: moment(
              `${moment(selectedDate)?.format(dateFormat)} ${moment(
                selectedTime?.to || moment()?.endOf('day'),
              )?.format('HH:mm')}`,
              'YYYY-MM-DD HH:mm',
            ),
          }
        : {
            startTime: moment(
              `${moment(selectedDate)?.format(dateFormat)} ${moment(
                moment()?.startOf('day'),
              )?.format('HH:mm')}`,
              'YYYY-MM-DD HH:mm',
            ),
            endTime: moment(
              `${moment(selectedDate)?.format(dateFormat)} ${moment(
                moment()?.endOf('day'),
              )?.format('HH:mm')}`,
              'YYYY-MM-DD HH:mm',
            ),
          }),
      location: geoAddress,
    };
    return event;
  };

  const disabledDateFun = (current) => {
    let weekOfDay = [];
    const holiday = [];
    forEach(currentPageSequence?.holidayList, (item) => {
      if (!currentPageSequence?.daysOff?.allowBookingOnHolidays) {
        holiday?.push(item?.date);
      }
    });
    if (!currentPageSequence?.daysOff?.allowBookingOnHolidays) {
      weekOfDay = map(
        currentPageSequence?.daysOff?.days,
        (item) => DaysOfWeek?.[item],
      );
    }
    if (showTimer) {
      // Can not select days before today
      return (
        weekOfDay?.includes(moment(current)?.day()) ||
        find(holiday, (date) => date === moment(current)?.format(dateFormat)) ||
        current < moment()?.startOf('day')
      );
    }
    return (
      weekOfDay?.includes(moment(current)?.day()) ||
      find(holiday, (date) => date === moment(current)?.format(dateFormat)) ||
      current < moment()?.add(1, 'day')?.startOf('day')
    );
  };

  useEffect(() => {
    if (
      widgetConfig?.reserveAppointment?.creditCard?.creditCardSetting?.enable &&
      !widgetConfig?.reserveAppointment?.contactInfo?.contactSetting?.enable
    ) {
      setSelectedStep(1);
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false,
      });
    } else if (
      widgetConfig?.reserveAppointment?.contactInfo?.contactSetting?.enable &&
      !widgetConfig?.reserveAppointment?.creditCard?.creditCardSetting?.enable
    ) {
      setSelectedStep(2);
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false,
      });
    } else {
      setSelectedStep(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);

  // this useEffect will find first enable date and set the first date which is available
  useEffect(() => {
    if (timeObj?.visible && !selectedDate) {
      if (
        widgetConfig?.availability?.displayType?.displayStyle === 'CALENDAR'
      ) {
        const dateCheck = setInterval(() => {
          // eslint-disable-next-line no-undef
          const firstDate = document?.querySelector(
            'td:not(.ant-picker-cell-disabled)',
          );
          if (firstDate) {
            if (
              moment(firstDate?.title)?.format(dateFormat) ===
              moment()?.format(dateFormat)
            ) {
              setSelectedDate(moment()); // if date is today then, we need current time to block slots which start time is passed
              if (
                selectedTime?.timeSlots?.some((item) => item.date === moment())
              ) {
                setSelectedTime((prev) => ({
                  ...prev,
                  timeSlots: prev?.timeSlots?.filter(
                    (item) => item?.date !== moment(),
                  ),
                }));
              } else if (widgetConfig?.availability?.multipleWindowDays) {
                setSelectedTime((prev) => ({
                  ...prev,
                  timeSlots: [...(prev?.timeSlots ?? []), { date: moment() }],
                }));
              } else {
                setSelectedTime((prev) => ({
                  ...prev,
                  timeSlots: [{ date: moment() }],
                }));
              }
            } else {
              setSelectedDate(moment(firstDate?.title));
              if (
                selectedTime?.timeSlots?.some(
                  (item) => item.date === moment(firstDate?.title),
                )
              ) {
                setSelectedTime((prev) => ({
                  ...prev,
                  timeSlots: prev?.timeSlots?.filter(
                    (item) => item?.date !== moment(firstDate?.title),
                  ),
                }));
              } else if (widgetConfig?.availability?.multipleWindowDays) {
                setSelectedTime((prev) => ({
                  ...prev,
                  timeSlots: [
                    ...(prev?.timeSlots ?? []),
                    { date: moment(firstDate?.title) },
                  ],
                }));
              } else if (timeSlotActive) {
                setSelectedTime((prev) => ({
                  ...prev,
                  timeSlots: [
                    ...(prev?.timeSlots ?? []),
                    {
                      date: moment(firstDate?.title),
                      ...{
                        ...currentPageSequence?.appointmentWindow?.slots?.[0],
                      },
                    },
                  ],
                }));
              } else {
                setSelectedTime((prev) => ({
                  ...prev,
                  timeSlots: [{ date: moment(firstDate?.title) }],
                }));
              }
            }
            // this specific block if first date is holiday and allowBookingOnHoliday  is on, then set title and message
            map(currentPageSequence?.holidayList, (item) => {
              if (item?.date === moment(firstDate?.title)?.format(dateFormat)) {
                setHolidayTitle(item?.title);
                setHolidayMessage(item?.message);
              }
            });
            clearInterval(dateCheck);
          }
        }, 100);
      }
      if (
        widgetConfig?.availability?.displayType?.displayStyle === 'WEEKDAYS'
      ) {
        const today = moment();
        const weekDataCopy = [];
        if (!weekData?.length) {
          const isTodayDisabled = disabledDateFun(today);
          if (!isTodayDisabled) {
            weekDataCopy?.push({
              originalDate: today,
              title: today?.format('dddd'),
              key: today?.format('dddd'),
            });
          }
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i < (isTodayDisabled ? 8 : 7); i++) {
            weekDataCopy?.push({
              originalDate: today?.clone()?.add(i, 'day'),
              title: today?.clone()?.add(i, 'day')?.format('dddd'),
              key: today?.clone()?.add(i, 'day')?.format('dddd'),
            });
          }
          setWeekData(weekDataCopy);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeObj]);

  useEffect(() => {
    refetchLeadStoreData();
    const todayDay = lowerCase(moment()?.format('dddd'));
    if (currentPageSequence?.cutOffTime?.[todayDay]?.checked) {
      if (
        moment(currentPageSequence?.cutOffTime?.[todayDay]?.time, [
          'h:mm A',
        ])?.isAfter(moment())
      ) {
        setShowTimer(true);
      }
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: false,
    });
    const currentQuoteObj = { ...storedData };
    if (currentQuoteObj) {
      form?.setFieldsValue({
        zipCode: currentQuoteObj?.zipCode,
        state: currentQuoteObj?.state,
        city: currentQuoteObj?.city,
        street: currentQuoteObj?.street,
        firstName: currentQuoteObj?.firstName,
        lastName: currentQuoteObj?.lastName,
        email: currentQuoteObj?.email,
        mobileNumber: currentQuoteObj?.mobileNumber,
      });
      setLocationData({
        zipCode: currentQuoteObj?.zipCode,
        state: currentQuoteObj?.state,
        city: currentQuoteObj?.city,
        street: currentQuoteObj?.street,
      });

      if (
        currentQuoteObj?.zipCode &&
        currentQuoteObj?.state &&
        currentQuoteObj?.city &&
        currentQuoteObj?.street
      ) {
        setLocationObj({
          ...locationObj,
          completed: true,
        });
      } else {
        setLocationObj({
          ...locationObj,
          completed: false,
        });
      }

      if (currentQuoteObj?.leadId) {
        form?.setFieldsValue({
          comments: currentQuoteObj?.comments || null,
          firstName: currentQuoteObj?.firstName || null,
          lastName: currentQuoteObj?.lastName || null,
          email: currentQuoteObj?.email || null,
          mobileNumber: currentQuoteObj?.mobileNumber || null,
        });
        if (currentQuoteObj?.contactMethods?.length > 0) {
          form?.setFieldsValue({
            contactMethods: currentQuoteObj?.contactMethods,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // analytics flow
  useEffect(() => {
    const sendData = {
      // need to store in state because form values is setting undefined when it is not visible
      ...(locationObj?.visible && {
        street,
        state: stateValue,
        city: cityValue,
        zipCode: zipCodeValue,
      }),
      ...(timeObj?.visible && {
        scheduleTime: {
          date: moment(selectedDate)?.format(dateFormat),
          timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
          selectedTime: {
            ...selectedTime,
            from: moment(selectedTime?.from)?.format('hh:mm a'),
            label: selectedTime?.label,
            to: moment(selectedTime?.to)?.format('hh:mm a'),
          },
        },
      }),
      ...(appointmentObj?.visible && {
        email: emailValue,
        firstName,
        lastName,
        comments,
        mobileNumber: formatPhoneNumberWithoutMask(mobileNumber),
        contactMethods,
        cardNumber,
        cardExpiry,
        cardCvc,
        appointmentWith:
          selectedStep === 1 ? 'Credit Card' : 'Customer Information',
      }),
    };
    setAnalyticsData({ ...analyticsData, ...sendData });
    sendAnalyticsData({ ...analyticsData, ...sendData });
    setSavedData({
      ...analyticsData,
      ...sendData,
      selectedStep,
      scheduleTime: {
        date: selectedDate,
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        selectedTime: {
          ...selectedTime,
          ...(selectedTime?.timeSlots && {
            selectedSlots: Object?.values(selectedTime?.timeSlots)?.map(
              (item) => ({
                date: item?.date,
                from: item?.from,
                label: item?.label,
                to: item?.to,
              }),
            ),
          }),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    street,
    stateValue,
    cityValue,
    zipCodeValue,
    emailValue,
    firstName,
    lastName,
    comments,
    mobileNumber,
    contactMethods,
    cardNumber,
    cardExpiry,
    cardCvc,
    selectedStep,
    selectedDate,
    selectedTime,
    locationObj,
    timeObj,
    appointmentObj,
  ]);

  useEffect(() => {
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data:
        locationObj?.completed &&
        timeObj?.completed &&
        appointmentObj?.visible &&
        selectedStep,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationObj, timeObj, appointmentObj]);

  const handleChangeDate = (date) => {
    setSelectedDate(date);
    if (selectedTime?.timeSlots?.some((item) => item.date === date)) {
      setSelectedTime((prev) => ({
        ...prev,
        timeSlots: prev?.timeSlots?.filter((item) => item?.date !== date),
      }));
    } else if (widgetConfig?.availability?.multipleWindowDays) {
      if (timeSlotActive) {
        setSelectedTime((prev) => ({
          ...prev,
          timeSlots: [
            ...(prev?.timeSlots ?? []),
            {
              date,
              ...{ ...currentPageSequence?.appointmentWindow?.slots?.[0] },
            },
          ],
        }));
      } else {
        setSelectedTime((prev) => ({
          ...prev,
          timeSlots: [...(prev?.timeSlots ?? []), { date }],
        }));
      }
    } else if (timeSlotActive) {
      setSelectedTime((prev) => ({
        ...prev,
        timeSlots: [
          {
            date,
            ...{ ...currentPageSequence?.appointmentWindow?.slots?.[0] },
          },
        ],
      }));
    } else {
      setSelectedTime((prev) => ({
        ...prev,
        timeSlots: [{ date }],
      }));
    }
    setHolidayTitle('');
    setHolidayMessage('');
    map(currentPageSequence?.holidayList, (item) => {
      if (item?.date === moment(date)?.format(dateFormat)) {
        setHolidayTitle(item?.title);
        setHolidayMessage(item?.message);
      }
    });
  };

  const getTimeSlotString = () => {
    if (selectedTime?.timeSlots?.length > 0) {
      const dates = groupBy(selectedTime?.timeSlots, 'date');
      return Object.entries(dates)?.map(
        ([key, value]) =>
          `
          ${moment(key)?.calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[last] dddd',
            nextWeek: 'dddd',
            sameElse: 'L',
          })}
            ${value?.map(
              (item) =>
                `${item?.label ? item?.label : ''} ${
                  currentPageSequence?.appointmentWindow?.showTime
                    ? `${moment(item?.from || moment()?.startOf('day'))?.format(
                        'LT',
                      )} -  ${moment(
                        item?.to || moment()?.endOf('day'),
                      )?.format('LT')}`
                    : ''
                }`,
            )}
          `,
      );
    }
    return [];
  };

  const PanelHeader = ({ item = null }) => {
    const isChecked = selectedTime?.timeSlots
      ?.map((items) => items?.date)
      ?.includes(item?.originalDate);
    return (
      <Checkbox
        disabled={disabledDateFun(item?.originalDate)}
        onClick={(e) => {
          e?.stopPropagation();
          if (isChecked) {
            setActiveCollapseKey((prev) => [...prev, item?.key]);
          }
        }}
        className={`common-checkbox ${
          !timeSlotActive ? 'checkbox-margin' : ''
        }`}
        checked={isChecked}
        onChange={() => handleChangeDate(item?.originalDate)}
      >
        <span
          onClick={() => {
            if (item?.originalDate !== selectedDate) {
              setActiveCollapseKey((prev) => [...prev, item?.key]);
            }
          }}
          className="font-size-12"
        >
          {item?.title}
        </span>
      </Checkbox>
    );
  };

  const PackageDisplay = () => {
    let total = 0;

    return (
      <div className="mt-25">
        {map(finalProjectData, (item, index) => {
          let totalAddOnProduct = 0;
          const addOnProductsName = [];

          if (item?.data?.productTitle) {
            total += parseFloat(item?.data?.productPrice);
            const addOnProducts = getAddOnProducts(item);

            addOnProducts?.map((items) => {
              total += parseFloat(items?.totalPrice);
              addOnProductsName?.push(`${items?.quantity} x ${items?.name}`);
              totalAddOnProduct += parseFloat(items?.totalPrice);
              return null;
            });

            return (
              <div key={`${item?.data?.productId}-${index}`}>
                <div className="single-project">
                  <span className="single-item">{`${item?.data?.productTitle} : ${item?.data?.quoteVariant}`}</span>
                  <span className="single-item">
                    {formatPrice(item?.data?.productPrice)}
                  </span>
                </div>
                {totalAddOnProduct > 0 && (
                  <div className="single-project">
                    <span className="single-item">
                      Add On Products
                      <Popover
                        trigger="hover"
                        content={map(addOnProductsName, (items, i) => (
                          <p key={`${items}-${i}`}>{items}</p>
                        ))}
                        overlayClassName="common-tooltip"
                        placement="bottom"
                      >
                        <InfoCircleOutlined className="info-icon ml-4" />
                      </Popover>
                    </span>
                    <span className="single-item">
                      {formatPrice(totalAddOnProduct)}
                    </span>
                  </div>
                )}
              </div>
            );
          }
        })}

        <Divider className="mx-12" />
        <div className="total">
          <span>{formatPrice(total)}</span>
        </div>
      </div>
    );
  };

  const handleChangeTime = (
    e,
    from = 'calendar',
    panel = null,
    panelIndex = null,
  ) => {
    if (from === 'week') {
      if (
        checkIfSlotIsAlreadyExists(
          selectedTime?.timeSlots,
          e,
          panel?.originalDate,
        )
      ) {
        setSelectedTime((prev) => ({
          timeSlots:
            prev?.timeSlots?.filter(
              (item) =>
                !(
                  item?.label === e?.label && item?.date === panel?.originalDate
                ),
            ) ?? [],
          panelIndex,
        }));
      } else if (widgetConfig?.availability?.multipleWindowSlots) {
        if (widgetConfig?.availability?.multipleWindowDays) {
          setSelectedTime((prev) => ({
            ...prev,
            timeSlots: [
              ...(prev?.timeSlots ?? []),
              { ...e, date: panel?.originalDate },
            ],
            panelIndex,
          }));
        } else if (panel?.originalDate === selectedDate) {
          setSelectedTime((prev) => ({
            ...prev,
            timeSlots: [
              ...(prev?.timeSlots ?? []),
              { ...e, date: panel?.originalDate },
            ],
            panelIndex,
          }));
        } else {
          setSelectedTime((prev) => ({
            ...prev,
            timeSlots: [{ ...e, date: panel?.originalDate }],
            panelIndex,
          }));
        }
      } else if (widgetConfig?.availability?.multipleWindowDays) {
        if (
          selectedTime?.timeSlots?.some(
            (item) => item?.date === panel?.originalDate,
          )
        ) {
          setSelectedTime((prev) => ({
            timeSlots: [
              ...(prev?.timeSlots?.filter(
                (item) => item?.date !== panel?.originalDate,
              ) ?? []),
              { ...e, date: panel?.originalDate },
            ],
            panelIndex,
          }));
        } else {
          setSelectedTime((prev) => ({
            ...prev,
            timeSlots: [
              ...(prev?.timeSlots ?? []),
              { ...e, date: panel?.originalDate },
            ],
            panelIndex,
          }));
        }
      } else {
        setSelectedTime({
          timeSlots: [{ ...e, date: panel?.originalDate }],
          panelIndex,
        });
      }
      setSelectedDate(panel?.originalDate);
    } else if (
      !isEmpty(selectedTime?.timeSlots) &&
      checkIfSlotIsAlreadyExists(selectedTime?.timeSlots, e, selectedDate)
    ) {
      setSelectedTime((prev) => ({
        timeSlots: [
          ...(prev?.timeSlots ?? [])?.filter(
            (item) => item?.label !== e?.label,
          ),
        ],

        panelIndex,
      }));
    } else if (widgetConfig?.availability?.multipleWindowSlots) {
      setSelectedTime((prev) => ({
        ...prev,
        timeSlots: [...(prev?.timeSlots ?? []), { ...e, date: selectedDate }],
      }));
    } else {
      setSelectedTime({
        timeSlots: [{ ...e, date: selectedDate }],
      });
    }
    setTimeObj({
      ...timeObj,
      completed: true,
    });
  };

  const getClassName = (value) => {
    let className;
    map(currentPageSequence?.holidayList, (item) => {
      if (item?.date === moment(value)?.format(dateFormat)) {
        className = 'single-date holiday';
      }
    });
    if (
      moment(selectedDate)?.format(dateFormat) ===
      moment(value)?.format(dateFormat)
    ) {
      className = 'single-date selected-date';
    }
    return className || 'single-date';
  };

  const dateFullCellRender = (value) => {
    const className = getClassName(value);
    return <span className={className}>{value?.date()}</span>;
  };

  const handleEditLocation = () => {
    setLocationObj({
      ...locationObj,
      visible: true,
    });
    setTimeObj({
      ...timeObj,
      visible: false,
    });
    setAppointmentObj({
      ...appointmentObj,
      visible: false,
    });
  };

  const handleEditTime = () => {
    setTimeObj({
      ...timeObj,
      visible: true,
    });
    setLocationObj({
      ...locationObj,
      visible: false,
    });
    setAppointmentObj({
      ...appointmentObj,
      visible: false,
    });
    setGeoAddress(
      `${form?.getFieldValue('street')} ${form?.getFieldValue(
        'city',
      )}, ${form?.getFieldValue('state')} ${form?.getFieldValue('zipCode')}`,
    );
  };

  const showStepTime = () => {
    if (locationObj?.completed) {
      setTimeObj({
        ...timeObj,
        visible: true,
      });
      setLocationObj({
        completed: true,
        visible: false,
      });
      setGeoAddress(
        `${form?.getFieldValue('street')} ${form?.getFieldValue(
          'city',
        )}, ${form?.getFieldValue('state')} ${form?.getFieldValue('zipCode')}`,
      );
      setAppointmentOpenOnce(true);
    }
  };

  const showStepAppointment = () => {
    if (
      (!timeSlotActive || (timeObj?.completed && selectedTime)) &&
      locationObj?.completed &&
      appointmentOpenOnce
    ) {
      setAppointmentObj({
        ...appointmentObj,
        visible: true,
      });
      setTimeObj({
        completed: true,
        visible: false,
      });
      setLocationObj({
        completed: true,
        visible: false,
      });
      setGeoAddress(
        `${form?.getFieldValue('street')} ${form?.getFieldValue(
          'city',
        )}, ${form?.getFieldValue('state')} ${form?.getFieldValue('zipCode')}`,
      );
    }

    const data = {
      date: selectedDate,
      selectedStep,
      selectedTime: {
        ...selectedTime,
        ...(selectedTime?.timeSlots && {
          selectedSlots: Object?.values(selectedTime?.timeSlots)?.map(
            (item) => ({
              date: item?.date,
              from: item?.from,
              label: item?.label,
              to: item?.to,
            }),
          ),
        }),
      },
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      street: form?.getFieldValue('street'),
    };
    setSavedData(data);
  };

  const handleChangeStreet = (e) => {
    const { value = '' } = e?.target;
    if (form?.getFieldValue('zipCode') !== locationData?.zipCode) {
      setLocationObj({
        ...locationObj,
        completed: false,
      });
    } else if (value?.length > 0) {
      setGeoAddress(
        `${value} ${form?.getFieldValue('city')}, ${form?.getFieldValue(
          'state',
        )} ${form?.getFieldValue('zipCode')}`,
      );
      setLocationObj({
        ...locationObj,
        completed: true,
      });
    } else {
      setLocationObj({
        ...locationObj,
        completed: false,
      });
    }
  };

  moment?.updateLocale('en', {
    week: {
      dow:
        DaysOfWeek?.[
          upperCase(widgetConfig?.availability?.displayType?.startOfTheWeek)
        ], // Date offset
    },
  });

  const handleAppointmentSteps = (step = null) => {
    const data = {
      date: selectedDate,
      selectedStep: step,
      selectedTime: {
        ...selectedTime,
        ...(selectedTime?.timeSlots && {
          selectedSlots: Object?.values(selectedTime?.timeSlots)?.map(
            (item) => ({
              date: item?.date,
              from: item?.from,
              label: item?.label,
              to: item?.to,
            }),
          ),
        }),
      },
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      street: form?.getFieldValue('street'),
    };
    setSelectedStep(step);
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: true,
    });
    setSavedData(data);
  };

  return (
    <div className="scheduled-appointment-page">
      <LoaderComponent spinning={projectLoading} setHeight={10}>
        <PackageDisplay />
      </LoaderComponent>
      <div className="location-section">
        {locationObj?.visible ? (
          <>
            <span className="location-title">
              {widgetConfig?.address?.title}
            </span>
            <Form.Item
              name="street"
              rules={[{ ...required, message: 'Please Enter Street' }]}
            >
              <InputComponent
                allowClear
                onChange={handleChangeStreet}
                placeholder="Street*"
              />
            </Form.Item>
            <Form.Item
              name="state"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select State',
                },
              ]}
            >
              <SelectComponent placeholder="State*" disabled>
                {map([], (state) => (
                  <Option key={state?.id} value={state?.name}>
                    {state?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  name="city"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select City',
                    },
                  ]}
                >
                  <SelectComponent placeholder="City*" disabled>
                    {map([], (city) => (
                      <Option key={city?.id} value={city?.name}>
                        {city?.name}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item rules={[zipCode]} name="zipCode">
                  <NumberComponent
                    disabled
                    allowClear
                    placeholder="Postal Code*"
                  />
                </Form.Item>
              </Col>
            </Row>
            {widgetConfig?.address?.descriptionCheck &&
              widgetConfig?.address?.descriptionText?.length > 0 && (
                <div className="step-description">
                  {widgetConfig?.address?.descriptionText}
                </div>
              )}
            <ArrowDownOutlined className="down-arrow d-flex justify-center" />
          </>
        ) : (
          <div className="info-card">
            {!widgetConfig?.address?.icon?.url ? (
              <LocationSecondary />
            ) : (
              <img
                alt="location"
                src={widgetConfig?.address?.icon?.url}
                width={18}
              />
            )}
            <span className="info-title">{geoAddress}</span>
            <Button
              type="link"
              className="edit-btn"
              onClick={handleEditLocation}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e?.key === 'Enter') {
                  if (handleEditLocation) {
                    handleEditLocation();
                  }
                }
              }}
            >
              edit
            </Button>
          </div>
        )}
      </div>
      <div className="time-section">
        {!timeObj?.visible ? (
          <>
            {!timeObj?.completed ? (
              <div
                className={`info-card ${
                  locationObj?.completed ? 'click-enabled' : 'click-disabled'
                }`}
                onClick={showStepTime}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    if (showStepTime) {
                      showStepTime();
                    }
                  }
                }}
              >
                {!widgetConfig?.availability?.icon?.url ? (
                  <CalendarDates />
                ) : (
                  <img
                    alt="location"
                    src={widgetConfig?.availability?.icon?.url}
                    width={18}
                  />
                )}
                <span className="info-title">
                  {widgetConfig?.availability?.title}
                </span>
              </div>
            ) : (
              <div
                className={`info-card ${
                  !locationObj?.completed && 'click-disabled'
                }`}
              >
                {!widgetConfig?.availability?.icon?.url ? (
                  <CalendarDates />
                ) : (
                  <img
                    alt="location"
                    src={widgetConfig?.availability?.icon?.url}
                    width={18}
                  />
                )}
                {getTimeSlotString()?.map((item) => (
                  <span key={item} className="info-title">
                    {item}
                  </span>
                ))}

                {locationObj?.completed && (
                  <>
                    <Button
                      type="link"
                      className="edit-btn"
                      onClick={handleEditTime}
                    >
                      edit
                    </Button>
                    <ICalendarLink event={getEvent()}>
                      <Button type="link" className="edit-btn">
                        add to calendar
                      </Button>
                    </ICalendarLink>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <span className="time-title">
              {widgetConfig?.availability?.title}
            </span>
            {widgetConfig?.availability?.displayType?.displayStyle ===
              'WEEKDAYS' && (
              <>
                {timeSlotActive ? (
                  <Collapse
                    className="week-panel"
                    ghost
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <UpOutlined rotate={isActive ? 0 : 180} />
                    )}
                    activeKey={activeCollapseKey}
                    onChange={(e) => {
                      setActiveCollapseKey(e);
                    }}
                  >
                    {map(weekData, (panel, panelIndex) => (
                      <Panel
                        collapsible={
                          disabledDateFun(panel?.originalDate) && 'disabled'
                        }
                        header={<PanelHeader item={panel} />}
                        key={panel?.key}
                      >
                        {timeSlotActive && (
                          <Row>
                            {map(
                              currentPageSequence?.appointmentWindow?.slots,
                              (item) => {
                                let checked = false;
                                const timeKeys =
                                  selectedTime?.timeSlots &&
                                  Object?.values(selectedTime?.timeSlots)
                                    ?.filter(
                                      (items) =>
                                        items?.date === panel?.originalDate,
                                    )
                                    ?.map((items) => items?.label);

                                if (timeKeys?.includes(item?.label)) {
                                  checked = true;
                                }
                                return (
                                  <Col key={item?.from} span={24}>
                                    <Checkbox
                                      checked={checked}
                                      onChange={() =>
                                        handleChangeTime(
                                          item,
                                          'week',
                                          panel,
                                          panelIndex,
                                        )
                                      }
                                      className="common-checkbox inside-time-checkbox"
                                      disabled={
                                        moment()?.format('L') ===
                                        moment(panel?.originalDate)?.format('L')
                                          ? moment(
                                              moment(item?.from)?.format('LT'),
                                              ['h:mm:ss A'],
                                            )?.isBefore(
                                              moment(moment()?.format('LT'), [
                                                'h:mm:ss A',
                                              ]),
                                            )
                                          : false
                                      }
                                    >
                                      {currentPageSequence?.appointmentWindow
                                        ?.showTime
                                        ? `${item?.label} ${moment(
                                            item?.from,
                                          )?.format('LT')} -
                            ${moment(item?.to)?.format('LT')}`
                                        : item?.label}
                                    </Checkbox>
                                  </Col>
                                );
                              },
                            )}
                          </Row>
                        )}
                      </Panel>
                    ))}
                  </Collapse>
                ) : (
                  map(weekData, (panel) => (
                    <PanelHeader key={panel?.key} item={panel} />
                  ))
                )}
              </>
            )}
            {widgetConfig?.availability?.displayType?.displayStyle ===
              'CALENDAR' && (
              <>
                <div id="expanded-date-picker">
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      inputReadOnly
                      value={selectedDate}
                      dateRender={dateFullCellRender}
                      popupClassName="calendar-picker"
                      open
                      onChange={handleChangeDate}
                      disabledDate={disabledDateFun}
                      getPopupContainer={(trigger) => trigger?.parentNode}
                    />
                  </ConfigProvider>
                </div>
                {timeSlotActive && (
                  <div className="ant-btn-group mt-16 d-flex justify-center flex-wrap">
                    {map(
                      currentPageSequence?.appointmentWindow?.slots,
                      (item) => {
                        let checked = false;
                        const timeKeys = selectedTime?.timeSlots?.map(
                          (items) => items?.label,
                        );

                        if (timeKeys?.includes(item?.label)) {
                          checked = true;
                        }
                        return (
                          <Button
                            disabled={
                              moment(selectedDate)?.format('L') ===
                              moment()?.format('L')
                                ? moment(moment(item?.from)?.format('LT'), [
                                    'h:mm:ss A',
                                  ])?.isBefore(
                                    moment(moment(selectedDate)?.format('LT'), [
                                      'h:mm:ss A',
                                    ]),
                                  )
                                : false
                            }
                            className="time-slots"
                            type={!checked ? 'default' : 'primary'}
                            onClick={() => handleChangeTime(item, 'calendar')}
                          >
                            {currentPageSequence?.appointmentWindow?.showTime
                              ? `${item?.label} ${moment(item?.from)?.format(
                                  'LT',
                                )} -
                            ${moment(item?.to)?.format('LT')}`
                              : item?.label}
                          </Button>
                        );
                      },
                    )}
                  </div>
                )}
                {/* TODO: commented for future use */}
                {/* {timeSlotActive && (
                  <Radio.Group
                    className="time-slots"
                    value={selectedTime}
                    onChange={(e) => handleChangeTime(e, 'calendar')}
                  >
                    {map(
                      currentPageSequence?.appointmentWindow?.slots,
                      (item) => (
                        <Radio.Button
                          key={item?.from}
                          value={item}
                          disabled={
                            moment(selectedDate)?.format('L') ===
                            moment()?.format('L')
                              ? moment(moment(item?.from)?.format('LT'), [
                                  'h:mm:ss A',
                                ])?.isBefore(
                                  moment(moment(selectedDate)?.format('LT'), [
                                    'h:mm:ss A',
                                  ]),
                                )
                              : false
                          }
                        >
                          {currentPageSequence?.appointmentWindow?.showTime
                            ? `${item?.label} ${moment(item?.from)?.format(
                                'LT',
                              )} -
                            ${moment(item?.to)?.format('LT')}`
                            : item?.label}
                        </Radio.Button>
                      ),
                    )}
                  </Radio.Group>
                )} */}
              </>
            )}
            {holidayTitle && (
              <span className="holiday-title holiday-bold">{holidayTitle}</span>
            )}
            {holidayMessage && (
              <span className="holiday-title">{holidayMessage}</span>
            )}
            {widgetConfig?.availability?.descriptionCheck &&
              widgetConfig?.availability?.descriptionText?.length > 0 && (
                <div className="step-description">
                  {widgetConfig?.availability?.descriptionText}
                </div>
              )}
            <ArrowDownOutlined className="down-arrow d-flex justify-center" />
          </>
        )}
      </div>
      <div className="appointment-section">
        {!appointmentObj?.visible ? (
          <>
            {!appointmentObj?.completed && (
              <div
                className={`info-card ${
                  (!timeSlotActive ||
                    (timeObj?.completed && selectedTime?.timeSlots)) &&
                  locationObj?.completed &&
                  appointmentOpenOnce
                    ? 'click-enabled'
                    : 'click-disabled'
                }`}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    if (showStepAppointment) {
                      showStepAppointment();
                    }
                  }
                }}
                onClick={showStepAppointment}
              >
                {!widgetConfig?.reserveAppointment?.icon?.url ? (
                  <CheckOutlined />
                ) : (
                  <img
                    alt="location"
                    src={widgetConfig?.reserveAppointment?.icon?.url}
                    width={18}
                  />
                )}
                <span className="info-title">
                  {widgetConfig?.reserveAppointment?.title}
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            <span className="appointment-title">
              {widgetConfig?.reserveAppointment?.title}
            </span>
            {!selectedStep ? (
              <>
                {widgetConfig?.reserveAppointment?.creditCard?.creditCardSetting
                  ?.enable && (
                  <div className="info-card appointment-card">
                    {!widgetConfig?.reserveAppointment.creditCard?.icon?.url ? (
                      <CreditCardOutlined />
                    ) : (
                      <img
                        alt="location"
                        src={
                          widgetConfig?.reserveAppointment.creditCard?.icon?.url
                        }
                        width={18}
                      />
                    )}
                    <span className="info-title appointment-description">
                      {widgetConfig?.reserveAppointment?.creditCard?.title}
                    </span>
                    <Button
                      className="common-button half-width"
                      htmlType="submit"
                      id="next-btn"
                      type="primary"
                      onClick={() => {
                        handleAppointmentSteps(1);
                      }}
                    >
                      {
                        widgetConfig?.reserveAppointment?.creditCard
                          ?.actionLabel
                      }
                    </Button>
                  </div>
                )}
                <Divider className="divider" />
                <div className="info-card appointment-card">
                  {!widgetConfig?.reserveAppointment.contactInfo?.icon?.url ? (
                    <PhoneOutlined rotate={90} />
                  ) : (
                    <img
                      alt="location"
                      src={
                        widgetConfig?.reserveAppointment.contactInfo?.icon?.url
                      }
                      width={18}
                    />
                  )}
                  <span className="info-title appointment-description">
                    {widgetConfig?.reserveAppointment?.contactInfo?.title}
                  </span>
                  <Button
                    className="common-button half-width"
                    htmlType="submit"
                    id="next-btn"
                    type="primary"
                    onClick={() => {
                      handleAppointmentSteps(2);
                    }}
                  >
                    {widgetConfig?.reserveAppointment?.contactInfo?.actionLabel}
                  </Button>
                </div>
                {widgetConfig?.reserveAppointment?.descriptionCheck &&
                  widgetConfig?.reserveAppointment?.descriptionText?.length >
                    0 && (
                    <div className="step-description mt-0">
                      {widgetConfig?.reserveAppointment?.descriptionText}
                    </div>
                  )}
              </>
            ) : (
              <>
                {selectedStep === 1 && (
                  <>
                    {widgetConfig?.reserveAppointment?.creditCard
                      ?.creditCardSetting?.descriptionCheck && (
                      <span className="tab-description">
                        {
                          widgetConfig?.reserveAppointment?.creditCard
                            ?.creditCardSetting?.descriptionText
                        }
                      </span>
                    )}
                    <Form.Item
                      className="email-margin"
                      rules={[
                        {
                          ...required,
                          message: 'Please Enter Your Email',
                        },
                        email,
                      ]}
                      name="email"
                    >
                      <InputComponent allowClear placeholder="Your Email*" />
                    </Form.Item>
                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item
                          rules={[
                            {
                              ...required,
                              message: 'Please Enter Your First Name',
                            },
                          ]}
                          name="firstName"
                          className="top-margin"
                        >
                          <InputComponent
                            allowClear
                            placeholder="Your First Name*"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          rules={[
                            {
                              ...required,
                              message: 'Please Enter Your Last Name',
                            },
                          ]}
                          name="lastName"
                          className="top-margin"
                        >
                          <InputComponent
                            allowClear
                            placeholder="Your Last Name*"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="card-section">
                      <CardSection />
                    </div>
                    <Form.Item name="comments">
                      <InputComponent
                        allowClear
                        placeholder={
                          widgetConfig?.reserveAppointment?.creditCard
                            ?.creditCardSetting?.optionalComments?.labelText
                        }
                      />
                    </Form.Item>
                    {widgetConfig?.reserveAppointment?.contactInfo
                      ?.contactSetting?.enable && (
                      <span
                        className="change-step"
                        onClick={() => {
                          handleAppointmentSteps(2);
                        }}
                      >
                        {widgetConfig?.reserveAppointment?.contactInfo?.title}
                      </span>
                    )}
                  </>
                )}
                {selectedStep === 2 && (
                  <>
                    {widgetConfig?.reserveAppointment?.contactInfo
                      ?.contactSetting?.descriptionCheck && (
                      <span className="tab-description">
                        {
                          widgetConfig?.reserveAppointment?.contactInfo
                            ?.contactSetting?.descriptionText
                        }
                      </span>
                    )}
                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item
                          rules={[
                            {
                              ...required,
                              message: 'Please Enter Your First Name',
                            },
                          ]}
                          name="firstName"
                          className="top-margin"
                        >
                          <InputComponent
                            allowClear
                            placeholder="Your First Name*"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          rules={[
                            {
                              ...required,
                              message: 'Please Enter Your Last Name',
                            },
                          ]}
                          name="lastName"
                          className="top-margin"
                        >
                          <InputComponent
                            allowClear
                            placeholder="Your Last Name*"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="mobileNumber"
                      rules={[
                        {
                          ...required,
                          message: 'Please Enter Your Phone Number',
                        },
                        () => ({
                          validator(rule, value) {
                            if (value) {
                              // eslint-disable-next-line no-param-reassign
                              value = value?.split(' ')?.join('');
                              const numberPattern = REGEX?.PHONE;
                              if (!numberPattern?.test(value)) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise?.reject(
                                  'should be a valid phone number',
                                );
                              }
                            }
                            return Promise?.resolve();
                          },
                        }),
                      ]}
                    >
                      <PatternFormat
                        placeholder="(999) 999-9999"
                        format="(###) ###-####"
                        mask="_"
                        customInput={InputComponent}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          ...required,
                          message: 'Please Enter Your Email',
                        },
                        email,
                      ]}
                      name="email"
                    >
                      <InputComponent allowClear placeholder="Your Email*" />
                    </Form.Item>
                    <Form.Item name="comments">
                      <InputComponent
                        allowClear
                        placeholder={
                          widgetConfig?.reserveAppointment?.contactInfo
                            ?.contactSetting?.optionalComments?.labelText
                        }
                      />
                    </Form.Item>
                    <Form.Item name="contactMethods" initialValue={['CALL_ME']}>
                      <Checkbox.Group className="common-checkbox fill-width">
                        <Row align="middle">
                          <Col span={8} key="LABEL">
                            <span className="font-size-12">
                              Contact Method:
                            </span>
                          </Col>
                          <Col span={8} key="CALL_ME">
                            <Checkbox
                              value="CALL_ME"
                              className="common-checkbox"
                            >
                              <span className="font-size-12">Call Me</span>
                            </Checkbox>
                          </Col>
                          <Col span={8} key="EMAIL_ME">
                            <Checkbox
                              value="EMAIL_ME"
                              className="common-checkbox"
                            >
                              <span className="font-size-12">Email Me</span>
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    {widgetConfig?.reserveAppointment?.creditCard
                      ?.creditCardSetting?.enable && (
                      <div
                        className="change-step"
                        onClick={() => {
                          handleAppointmentSteps(1);
                        }}
                      >
                        {widgetConfig?.reserveAppointment?.creditCard?.title}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AppointmentWidgetComponent;
