import { CheckOutlined } from '@ant-design/icons';
import { isBoolean } from 'lodash';
import React, { useEffect, useState } from 'react';
import { PORTAL_WIDTH, staticFieldKeys } from '../common/constants';
import { formatPrice } from '../common/utils';
import ModalComponent from './ModalComponent';

const QuoteCompareModal = ({
  showComparisonModal = false,
  setShowComparisonModal,
  productData = null,
}) => {
  const [compareConfigsData, setCompareConfigData] = useState({});

  const getDynamicDetailsValue = (prodData, type, item) => {
    let value = '-';
    const productValue = prodData?.[type]?.productItemInfo?.filter(
      (items) => items?.isPrimary,
    )?.[0];
    value =
      productValue?.dynamicFields?.find(
        (innerItem) => innerItem?.dfKey === item?.key,
      )?.dfValue || '-';
    if (item?.config?.PICK_LIST) {
      value = item?.dynamicListItem?.find(
        (innerItem) =>
          innerItem.value ===
          productValue?.dynamicFields?.find(
            (innerItem2) => innerItem2?.dfKey === item?.key,
          )?.dfValue,
      )?.label;
    }
    if (item?.config?.BOOLEAN) {
      if (
        productValue?.dynamicFields?.find(
          (innerItem) => innerItem?.dfKey === item?.key,
        )?.dfValue === undefined
      ) {
        value = '-';
      } else {
        value =
          item?.config?.BOOLEAN?.[
            productValue?.dynamicFields?.find(
              (innerItem) => innerItem?.dfKey === item?.key,
            )?.dfValue
              ? 'trueValue'
              : 'falseValue'
          ];
      }
    }

    return value;
  };

  useEffect(() => {
    let staticKeys = [];
    let dynamicKeys = [];
    if (productData?.compareConfigData?.staticKeys) {
      staticKeys = productData?.compareConfigData?.staticKeys?.map((item) => ({
        key: item?.id,
        label: staticFieldKeys?.[item]?.label,
        values: {
          good:
            productData?.good?.productItemInfo?.filter(
              (items) => items?.isPrimary,
            )?.[0]?.[staticFieldKeys?.[item]?.key] || '-',
          better:
            productData?.better?.productItemInfo?.filter(
              (items) => items?.isPrimary,
            )?.[0]?.[staticFieldKeys?.[item]?.key] || '-',
          best:
            productData?.best?.productItemInfo?.filter(
              (items) => items?.isPrimary,
            )?.[0]?.[staticFieldKeys?.[item]?.key] || '-',
        },
      }));
    }
    if (productData?.compareConfigData?.dynamicFields) {
      dynamicKeys = productData?.compareConfigData?.dynamicFields?.map(
        (item) => ({
          key: item?.id,
          label: item?.label,
          values: {
            good: getDynamicDetailsValue(productData, 'good', item),
            better: getDynamicDetailsValue(productData, 'better', item),
            best: getDynamicDetailsValue(productData, 'best', item),
          },
        }),
      );
    }
    const tempData = {
      price: {
        good: productData?.good?.sellingPrice || 0,
        better: productData?.better?.sellingPrice || 0,
        best: productData?.best?.sellingPrice || 0,
      },
      fields: [...staticKeys, ...dynamicKeys],
    };

    setCompareConfigData(tempData);
  }, [productData]);

  return (
    <ModalComponent
      open={showComparisonModal}
      footer={null}
      onCancel={() => {
        setShowComparisonModal(false);
      }}
      destroyOnClose
      mask={false}
      width={PORTAL_WIDTH}
      wrapClassName="product-comparison-modal"
    >
      <div>
        <div className="product-comparison-modal-label">
          {productData?.compareConfigData?.title}
        </div>
        <div className="d-flex justify-around flex-wrap">
          <div className="product-comparison-modal-label-header">Good</div>
          <div className="product-comparison-modal-label-header">Better</div>
          <div className="product-comparison-modal-label-header">Best</div>
          <div className="product-comparison-modal-label-header">
            {formatPrice(compareConfigsData?.price?.good || 0) || '-'}
          </div>
          <div className="product-comparison-modal-label-header">
            {formatPrice(compareConfigsData?.price?.better || 0) || '-'}
          </div>
          <div className="product-comparison-modal-label-header">
            {formatPrice(compareConfigsData?.price?.best || 0) || '-'}
          </div>
        </div>

        <div className="lines">
          <div className="quote-compare-inner-div">
            <div className="grid-lines">
              <div className="grid-line-item" />
              <div className="grid-line-item" />
            </div>
            {compareConfigsData?.fields?.map((item, index) => (
                <div
                  key={item?.id}
                  className={`quote-compare-modal-content ${
                    index === 0 ? 'mt-72' : 'mt-48'
                  }`}
                >
                  <div className="d-flex justify-center">
                    <div className="product-comparison-modal-label-main-header">
                      {item?.label}
                    </div>
                  </div>
                  <div className="d-flex justify-around flex-wrap mt-12">
                    {isBoolean(item?.values?.good) ? (
                      <div className="product-comparison-modal-label-sub-header">
                        <CheckOutlined />
                      </div>
                    ) : (
                      <div
                        className="product-comparison-modal-label-sub-header"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item?.values?.good || '-',
                        }}
                      />
                    )}

                    {isBoolean(item?.values?.better) ? (
                      <div className="product-comparison-modal-label-sub-header">
                        <CheckOutlined />
                      </div>
                    ) : (
                      <div
                        className="product-comparison-modal-label-sub-header"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item?.values?.better || '-',
                        }}
                      />
                    )}

                    {isBoolean(item?.values?.best) ? (
                      <div className="product-comparison-modal-label-sub-header">
                        <CheckOutlined />
                      </div>
                    ) : (
                      <div
                        className="product-comparison-modal-label-sub-header"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item?.values?.best || '-',
                        }}
                      />
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default QuoteCompareModal;
