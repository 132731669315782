import { Col, Form, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { DEFAULT_STAIRS_DEPTH_OPTIONS } from '../../common/constants';
import { calculateVolumeForOtherWidget } from '../../common/utils';
import DecimalNumberComponent from '../DecimalNumberComponent';
import NumberComponent from '../NumberComponent';
import SelectComponent from '../SelectComponent';

const { Option } = SelectComponent;

const ManualEntryForm = ({
  manualEntry = null,
  form = null,
  setShowModal,
  area = 0,
  setSavedData,
}) => {
  const {
    dispatch,
    state: { storedData },
  } = useContext(AppContext);

  const isLobTypeSealant = storedData?.lobObject?.key === 'SEALANT';

  const depthValue = Form?.useWatch(
    ['dimension', 'other', 'manualEntryDepth'],
    form,
  );
  const areaValue = Form?.useWatch(
    ['dimension', 'other', 'manualEntryArea'],
    form,
  );

  useEffect(() => {
    if (area > 0) {
      form?.setFieldValue(['dimension', 'other', 'manualEntryArea'], area);
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true,
      });
    } else {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    if (areaValue > 0) {
      if (depthValue) {
        const finalVolume = calculateVolumeForOtherWidget(
          depthValue,
          areaValue,
        );

        // dynamic Unit of measure changes
        const areaKey = manualEntry?.unitOfMeasureAreaKey;
        const depthKey = manualEntry?.unitOfMeasureDepthKey;
        const volumeKey = manualEntry?.unitOfMeasureVolumeKey;

        const defaultAreaUnit = manualEntry?.unitOfMeasureAreaDefaultUnit;
        const defaultDepthUnit = manualEntry?.unitOfMeasureDepthDefaultUnit;
        const defaultVolumeUnit = manualEntry?.unitOfMeasureVolumeDefaultUnit;

        const dimensionFilter = [
          {
            key: areaKey,
            value: areaValue,
            unit: defaultAreaUnit,
          },
          {
            key: depthKey,
            value: depthValue,
            unit: defaultDepthUnit,
          },
          {
            key: volumeKey,
            value: finalVolume,
            unit: defaultVolumeUnit,
          },
        ];
        setSavedData((prev) => ({
          ...prev,
          dimensionFilter,
        }));

        form?.setFieldValue(
          ['dimension', 'other', 'manualEntryVolume'],
          finalVolume,
        );
        dispatch({
          type: 'SET_SHOW_SUBMIT_BTN',
          data: true,
        });
      } else {
        const areaKey = manualEntry?.unitOfMeasureAreaKey;
        const defaultAreaUnit = manualEntry?.unitOfMeasureAreaDefaultUnit;
        const dimensionFilter = [
          {
            key: areaKey,
            value: areaValue,
            unit: defaultAreaUnit,
          },
        ];
        setSavedData((prev) => ({
          ...prev,
          dimensionFilter,
        }));
        dispatch({
          type: 'SET_SHOW_SUBMIT_BTN',
          data: true,
        });
      }
    } else {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false,
      });
      form?.setFieldValue(
        ['dimension', 'other', 'manualEntryVolume'],
        undefined,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthValue, areaValue]);

  const handleAreaChange = (changedValue) => {
    if (!changedValue) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false,
      });
    }
  };
  return (
    <>
      <Row>
        <Col span={23}>
          <Form.Item
            name={['dimension', 'other', 'manualEntryArea']}
            className={manualEntry?.areaToolTipCheck ? 'has-description' : ''}
            rules={[
              {
                required: true,
                message: 'Please Enter Manual Entry Area',
              },
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={manualEntry?.areaText}
              decimalValue={2}
              onChange={handleAreaChange}
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          <div className="measurement-span">sq/ft</div>
        </Col>
      </Row>
      {manualEntry?.areaToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.areaToolTipText}</span>
        </div>
      )}
      {!isLobTypeSealant && (
        <Row>
          <Col span={23}>
            <Form.Item
              name={['dimension', 'other', 'manualEntryDepth']}
              className={
                manualEntry?.depthToolTipCheck ? 'has-description' : ''
              }
              rules={[
                {
                  required: true,
                  message: 'Please Enter Manual Entry Depth',
                },
              ]}
            >
              <SelectComponent
                placeholder={manualEntry?.depthText}
                className="stairs-select"
                allowClear
                disabled
              >
                {map(DEFAULT_STAIRS_DEPTH_OPTIONS, (item) => (
                    <Option key={item?.value} value={item?.value}>
                      {item?.label}
                    </Option>
                  ))}
              </SelectComponent>
            </Form.Item>
          </Col>
        </Row>
      )}
      {!isLobTypeSealant && manualEntry?.depthToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.depthToolTipText}</span>
        </div>
      )}
      {!isLobTypeSealant && (
        <Row>
          <Col span={23}>
            <Form.Item
              name={['dimension', 'other', 'manualEntryVolume']}
              className={
                manualEntry?.volumeToolTipCheck ? 'has-description' : ''
              }
              rules={[
                {
                  required: true,
                  message: 'Please Enter Manual Entry Volume',
                },
              ]}
            >
              <NumberComponent
                placeholder={manualEntry?.volumeText}
                readOnly
                className="dimension-read-only"
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <div className="measurement-span">cu/yds</div>
          </Col>
        </Row>
      )}
      {!isLobTypeSealant && manualEntry?.volumeToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.volumeToolTipText}</span>
        </div>
      )}

      {manualEntry?.descriptionCheck && (
        <div className="mt-16">
          <span>{manualEntry?.descriptionText}</span>
        </div>
      )}
      {!isLobTypeSealant && (
        <div
          className="measurement-tips-link"
          onClick={() => setShowModal(true)}
        >
          Measurement Tips
        </div>
      )}
    </>
  );
};

export default ManualEntryForm;
